import React, { Component } from "react";

export default class XLPlusPlage extends Component {
  componentDidMount() {
    document.title = "XL+ | excellinked";
  }
  render() {
    return (
      <div onLoad={this.props.loadPage} className="margin-footer">



        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1">
                <h1 className="title">
                  Acerca de <span>XL+ (excellinked+)</span>
                </h1>
                <div className="text-content">
                  <p>
                    Hoy en día, la creación de empresas innovadoras son un factor importante para el desarrollo sostenible de cualquier negocio. Es por ello que creamos <b>XL Plus (excellinked+)</b> como expansión del grupo <b>XL (excellinked)</b>.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a href="img/xlplus.jpg" className="progressive replace">
                    <img
                      src="img/xlplusblur.jpg"
                      className="preview"
                      alt="about"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>




        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <div className="center">
                  <h2 data-aos="fade-up" className="subtitle">
                    Renovación+ Plus
                  </h2>
                </div>
                <div className="group-faq">
                  <div className="group-faq-content grid grid-md-1">
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>¿Qué es XL+ (excellinked+) ?</b>
                      </p>
                      <p>
                        Es el grupo de empresas que conforman el grupo XL (excellinked). XL+ (excellinked Plus) es creado con el objetivo estratégico de mantener joven nuestro NÚCLEO de negocio (aplicación y desarrollo de tecnología) y así producir un mayor grado de sostenibilidad en el tiempo. 
                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>¿Me lo explicas en simple?</b>
                      </p>
                      <p>
                        XL+ nos dará mayor fortaleza para seguir cumpliendo con las exigencias, limitaciones y golpes coyunturales ocasionados por la pandemia COVID-19. De esta forma, apoyaremos mejor a nuestros clientes cuyos negocios fueron duramente afectados.

                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>
                          ¿Qué utilidad tendrá XL+ (excellinked+) ?
                        </b>
                      </p>
                      <p>
                        Al ingresar una nueva empresa al grupo, podremos canalizar mejor nuestros recursos con la sinergia entre aquellos partners y socios que confían en los 10 años (al 2021) de experiencia y trayectoria de excellinked (XL). 
                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>
                          ¿Habrá algún cambio con las condiciones y precios que actualmente tengo contratado?
                        </b>
                      </p>
                      <p>
                        No, TODO se mantendrá igual. Con XL+ nos enfocaremos en mejorar las ofertas de nuestros productos y servicios, para apoyar mejor a los negocios que necesitan contar con un soporte tecnológico para sus negocios. 
                      </p>
                     
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>
                          ¿Si necesito ayuda técnica, o de facturación o quiero contratar algo más?
                        </b>
                      </p>
                      <p>
                        Todos nuestros canales de comunicación seguirán vigentes. Ya sea por medio de nuestro website (chat), redes sociales, o WhatsApp business, la atención será en el mismo horario regular.
                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>
                          ¿Y cuando empezará XL+ (excellinked+)?
                        </b>
                      </p>
                      <p>
                        Los planes originales fueron para Junio del 2020, los cuales se retrasaron por razones de pandemia, pero ya estamos empezando con fuerza.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Nuestras cuentas XL+
              </h2>
            </div>
            <div className="row">
              <div className="col-offset-xs-1 col-xs-10">
                <div className="grid grid-md-2">
                  <div className="column">
                    {/* <hr /> */}
                    <div data-aos="fade-up" className="group-banco">
                      <div className="group-banco-title">
                        <p className="banco-bcp">
                          <b>BCP Banco de Crédito</b>
                        </p>
                      </div>
                      <div className="group-banco-content">
                        <div>
                          <p>
                            <b>AHORRO NEGOCIOS US$</b>
                          </p>
                          <p>19301046664135</p>
                        </div>
                        <div>
                          <p>
                            <b>CCI (US$)</b>
                          </p>
                          <p>002193101046664135-12</p>
                        </div>
                        <div>
                          <p>
                            <b>AHORRO NEGOCIOS S/</b>
                          </p>
                          <p>19301046652023</p>
                        </div>
                        <div>
                          <p>
                            <b>CCI (S/)</b>
                          </p>
                          <p>002193101046652023-14</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    {/* <hr /> */}
                    <div data-aos="fade-up" className="group-banco">
                      <div className="group-banco-title">
                        <p className="banco-interbank">
                          <b>Banco de la Nación</b>
                        </p>
                      </div>
                      <div className="group-banco-content">
                        <div>
                          <p>
                            <b>CUENTA DETRACCIONES</b>
                          </p>
                          <p>00-048-149529</p>
                        </div>
                        {/* <div> */}
                        {/*   <p> */}
                        {/*     <b>Razón social: XL PLUS</b> */}
                        {/*   </p> */}
                        {/*   <p>RUC: 20607112445</p> */}
                        {/* </div> */}
                        <div>
                        {/*   <p> */}
                        {/*     <b>CUENTAS DÓLARES</b> */}
                        {/*   </p> */}
                        {/*   <p>200‑3001798429</p> */}
                        {/* </div> */}
                        {/* <div> */}
                        {/*   <p> */}
                        {/*     <b>CCI (US$)</b> */}
                        {/*   </p> */}
                        {/*   <p>003-200-003001798429-32</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section> */}
        {/*   <div className="container"> */}
        {/*     <div className="center"> */}
        {/*       <h2 data-aos="fade-up" className="subtitle"> */}
        {/*         Nuestra cuenta */}
        {/*       </h2> */}
        {/*     </div> */}
        {/*     <div className="row"> */}
        {/*       <div className="col-offset-xs-1 col-xs-10"> */}
        {/*         <div className="grid grid-md-2"> */}
        {/*           <div className="column"> */}
        {/*             <div data-aos="fade-up" className="group-banco"> */}
        {/*               <div className="group-banco-title"> */}
        {/*                 <p className="banco-bcp"> */}
        {/*                   <b>BCP</b> */}
        {/*                 </p> */}
        {/*               </div> */}
        {/*               <div className="group-banco-content"> */}
        {/*                 <div> */}
        {/*                   <p> */}
        {/*                     <b>CUENTAS DÓLARES</b> */}
        {/*                   </p> */}
        {/*                   <p>193-01046664-1-35</p> */}
        {/*                 </div> */}
        {/*                 <div> */}
        {/*                   <p> */}
        {/*                     <b>CCI (US$)</b> */}
        {/*                   </p> */}
        {/*                   <p>00219310104666413512</p> */}
        {/*                 </div> */}
        {/*                 <div> */}
        {/*                   <p> */}
        {/*                     <b>AHORRO NEGOCIOS SOLES</b> */}
        {/*                   </p> */}
        {/*                   <p>193-01046652-0-23</p> */}
        {/*                 </div> */}
        {/*                 <div> */}
        {/*                   <p> */}
        {/*                     <b>CCI (S/.)</b> */}
        {/*                   </p> */}
        {/*                   <p>00219310104665202314</p> */}
        {/*                 </div> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*         </div> */}
        {/*       </div> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </section> */}
      </div>
    );
  }
}
