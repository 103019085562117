import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ContactSeccion from './../components/contact';

export default class MiIp extends Component {
  constructor() {
    super();
    this.state = {
      ip: [],
    };
  }

  componentDidMount() {
    document.title = 'Cuál es mi IP | excellinked';
    fetch('//freegeoip.live/json/')
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          ip: data,
        });
      });
    this.props.loadPage();
  }

  render() {
    return (
      <div>
        <div className="ip-page center flex align-items-center">
          <p>Tu IP es:</p>
          <h1 className="title">{this.state.ip.ip}</h1>
          <Link className="link link-accent" to="/">
            volver al inicio <i className="material-icons">trending_flat</i>
          </Link>
        </div>
        <section className="ip-service container">
          <div className="center">
            <div className="message-content">
              <p className="title">
                ¿Sabías que las ventas online mueven más de $2.800 millones al
                año?
              </p>
              <p className="title">
                En Perú, eso significa una mejora creciente del 25% de las
                ventas
              </p>
              <p className="title">
                Impulsa tu negocio hoy, toma ya la decisión
              </p>
            </div>
            <h2 data-aos="fade-up" className="subtitle">
              ¡Impulsa tu marca digital hoy!
            </h2>
          </div>
          <div className="row">
            <div data-aos="fade-up" className="grid grid-md-2 services-group">
              <div className="column">
                <div className="home-services-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="57.49"
                    height="42"
                    viewBox="0 0 57.49 34"
                  >
                    <path
                      fill="#ff2651"
                      d="M56.88,31.26,49,25.31V1.52A1.52,1.52,0,0,0,47.43,0H10.07A1.52,1.52,0,0,0,8.55,1.52V25.31L.61,31.26A1.52,1.52,0,0,0,1.53,34H56a1.52,1.52,0,0,0,.92-2.74ZM11.6,3H45.9v21.5H11.6ZM6.1,31l4.48-3.35H46.92L51.39,31Z"
                    />
                  </svg>
                  <h5>Web Apps+</h5>
                  <p>
                    Abarcamos TODO: desde el diseño hasta el desarrollo. Así te
                    garantizamos el cumplimiento de estándares y uso de últimas
                    tecnologías.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="home-services-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="42"
                    viewBox="0 0 25 39"
                  >
                    <path
                      fill="#ff2651"
                      d="M20.84,0H4.16A4.23,4.23,0,0,0,0,4.3V34.7A4.23,4.23,0,0,0,4.16,39H20.84A4.23,4.23,0,0,0,25,34.7V4.3A4.23,4.23,0,0,0,20.84,0ZM4.16,3H20.84A1.25,1.25,0,0,1,22,4.3V6H3V4.3A1.25,1.25,0,0,1,4.16,3ZM22,9V27H3V9ZM20.84,36H4.16A1.25,1.25,0,0,1,3,34.7V30H22v4.7A1.25,1.25,0,0,1,20.84,36Z"
                    />
                    <circle fill="#ff2651" cx="12.5" cy="33" r="1.5" />
                  </svg>
                  <h5>Android+ & iOS+</h5>
                  <p>
                    Apps móviles y juegos desarrollados desde el UX hasta su
                    publicación y también con full mantenimiento y soporte.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="home-services-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="42"
                    viewBox="0 0 37 37"
                  >
                    <path
                      fill="#ff2651"
                      d="M24.9,23.9h3c0.6,0,1.1-0.3,1.4-0.7c0.1-0.1,0.1-0.2,0.1-0.3l7.3-12.8c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2
                    c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1C37,9.1,37,9,37,9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
                    c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0h-25l-1.1-6c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1C9.1,1.1,9.1,1,9,1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0H1.7C0.8,0.1,0,0.9,0,1.8s0.8,1.7,1.7,1.7h4.3l4.2,23.1c-1.7,0.9-2.8,2.7-2.8,4.8c0,3,2.5,5.5,5.5,5.5c2.4,0,4.5-1.6,5.2-3.8h6.3c0.7,2.2,2.8,3.8,5.2,3.8c3,0,5.5-2.5,5.5-5.5s-2.5-5.5-5.5-5.5c-2.4,0-4.5,1.6-5.2,3.8h-6.3c-0.7-1.9-2.4-3.4-4.4-3.7l-0.4-2.1H24.9z M29.6,29.4c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S28.5,29.4,29.6,29.4z M32.3,11l-5.4,9.5H12.7L10.9,11H32.3z M15,31.4c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S15,30.3,15,31.4z"
                    />
                  </svg>
                  <h5>e-Commerce+</h5>
                  <p>
                    Ofrecemos soluciones en e-Commerce, para impulsar a que tus
                    clientes reconozcan tu marca y se fidelicen.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="home-services-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40.29"
                    height="42"
                    viewBox="0 0 40.28 40.44"
                  >
                    <path
                      fill="#ff2651"
                      d="M33.83,2A41,41,0,0,0,20.14,0,41,41,0,0,0,6.46,2C4.86,2.61,0,4.34,0,8.16c0,0,0,.09,0,.13V20.12s0,.07,0,.1,0,.11,0,.17V32.18s0,.07,0,.1c0,5.36,10.13,8.16,20.14,8.16s20.14-2.8,20.14-8.16V8.16C40.28,4.34,35.42,2.61,33.83,2Zm3.46,18.18c0,2.1-6.68,5.16-17.14,5.16s-17-3-17.13-5.11V12.69a17.37,17.37,0,0,0,3.44,1.6,41,41,0,0,0,13.69,2,41,41,0,0,0,13.69-2,17.34,17.34,0,0,0,3.46-1.6ZM7.46,4.86A38.07,38.07,0,0,1,20.14,3,38.07,38.07,0,0,1,32.82,4.86c2.84,1,4.46,2.22,4.46,3.3s-1.63,2.28-4.46,3.3a38,38,0,0,1-12.68,1.86A38,38,0,0,1,7.46,11.46c-2.79-1-4.4-2.17-4.45-3.24V8.16s0,0,0,0C3,7.05,4.66,5.87,7.46,4.86ZM20.14,37.44c-10.41,0-17.06-3-17.14-5.13,0,0,0,0,0,0V24.72c3.75,2.41,10.46,3.66,17.13,3.66s13.4-1.25,17.14-3.67v7.57C37.28,34.39,30.61,37.44,20.14,37.44Z"
                    />
                  </svg>
                  <h5>Servidores</h5>
                  <p>
                    Servidores de última generación, administrados por el propio
                    equipo de XL+ que van de la mano con el mejor soporte técnico
                    24x7.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSeccion />
      </div>
    );
  }
}
