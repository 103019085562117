import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NoMatch extends Component {
  componentDidMount() {
    this.props.loadPage();
    document.title = 'Página no encontrada | excellinked';
  }
  render() {
    return (
      <div className="error-page center flex align-items-center">
        <Link className="link link-accent" to="/">
          volver al inicio <i className="material-icons">trending_flat</i>
        </Link>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="180"
          height="180"
          viewBox="0 0 145 145"
        >
          <title>error</title>
          <path
            id="line-smile"
            fill="#DDE9F1"
            d="M48.8,112l-3.31-.14c-.65-.07-.94-.58-.94-1.37C45.06,102.23,51,93,72.92,93s27.87,9.22,28.3,17.42c.07.79-.29,1.3-.94,1.37L97,112a1.06,1.06,0,0,1-1.3-.79C93.88,103.1,86,100,72.92,100s-21,3.1-22.83,11.16A1.09,1.09,0,0,1,48.8,112Z"
            transform="translate(-0.39)"
          />
          <path
            fill="#DDE9F1"
            d="M45.44,58.21,36.37,46a1.12,1.12,0,0,1,.86-1.94h4.68a2.08,2.08,0,0,1,1.73.79l5.76,8.28h.07l5.9-8.28A2,2,0,0,1,57,44.1h4.75c1.22,0,1.58.94.86,1.87L53.58,58.14,62.72,71c.65.94.29,1.87-.86,1.87H57.32A2,2,0,0,1,55.81,72l-6.34-8.93H49.4l-6.48,9.22a1.38,1.38,0,0,1-1.15.58H36.94c-1.22,0-1.51-.94-.86-1.87Z"
            transform="translate(-0.39)"
          />
          <path
            fill="#DDE9F1"
            d="M92.44,58.21,83.37,46a1.12,1.12,0,0,1,.86-1.94h4.68a2.08,2.08,0,0,1,1.73.79l5.76,8.28h.07l5.9-8.28A2,2,0,0,1,104,44.1h4.75c1.22,0,1.58.94.86,1.87l-9.07,12.17L109.72,71c.65.94.29,1.87-.86,1.87h-4.54a2,2,0,0,1-1.51-.86l-6.34-8.93H96.4l-6.48,9.22a1.38,1.38,0,0,1-1.15.58H83.94c-1.22,0-1.51-.94-.86-1.87Z"
            transform="translate(-0.39)"
          />
          <path
            fill="#DDE9F1"
            d="M72.89,8A64.5,64.5,0,1,1,8.39,72.5,64.57,64.57,0,0,1,72.89,8m0-8a72.5,72.5,0,1,0,72.5,72.5A72.5,72.5,0,0,0,72.89,0Z"
            transform="translate(-0.39)"
          />
        </svg>
        <h1 className="title">
          <span>¡Upss...!</span>
        </h1>
        <p>La página que intentas visitar ya no está disponible.</p>
      </div>
    );
  }
}
