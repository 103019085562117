import React, { Component } from 'react';
import ContactSeccion from './../components/contact';

export default class DomoticaPage extends Component {
  // pageActive pageEnter
  componentDidMount() {
    document.title = 'Domotica XL+ | excellinked+';
  }

  render() {
    return (
      <div onLoad={this.props.loadPage}>
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1">
                <h1 className="title">
                  Domótica+ & <span>automation+</span>
                </h1>
                <div className="text-content">
                  <p>
                    El concepto de “automation” se basa en la aplicación del
                    “Internet de las Cosas” donde procesos y funciones que
                    normalmente aplicamos en nuestro hogar y oficina, logramos
                    hacerlo con resultados más rápidos y con un amplio espectro
                    de ventajas, a costos bajos.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a href="img/domotica.jpg" className="progressive replace">
                    <img
                      src="img/domoticablur.jpg"
                      className="preview"
                      alt="domotica"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Nuevas Soluciones
              </h2>
            </div>
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <div className="group-faq">
                  <div data-aos="fade-up" className="group-faq-title">
                    <p>
                      <b>Domótica para hogares</b>
                    </p>
                    <br />
                    <p style={{ lineHeight: 1.75 }}>
                      Soluciones eficientes de gran calidad y bajo costo para
                      hogares. Integramos equipos de última generación con
                      aplicaciones para Smartphones y tabletas.
                    </p>
                  </div>
                  <div className="row">
                    <div className="group-faq-content grid grid-md-1">
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>Ventajas</b>
                        </p>
                        <p>
                          - Solución de seguridad que puede prevenir y
                          solucionar incidentes de riesgo.
                        </p>
                        <p>
                          - Control de los dispositivos para un menor consumo de
                          energía.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-faq">
                  <div data-aos="fade-up" className="group-faq-title">
                    <p>
                      <b>E-commerce</b>
                    </p>
                    <br />
                    <p style={{ lineHeight: 1.75 }}>
                      Sistemas desarrollados en web y móviles, a medida, que
                      serán la herramienta fundamental para entender los hábitos
                      de consumo de clientes y poder lanzar campañas con un alto
                      nivel de predictibilidad.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSeccion />
      </div>
    );
  }
}
