import React, { Component } from 'react';

import Player from './../components/player';
import ContactSeccion from './../components/contact';

export default class ProjectPage extends Component {
  componentDidMount() {
    document.title = 'Proyectos XL | excellinked';
  }
  render() {
    return (
      <div onLoad={this.props.loadPage}>
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1">
                <h1 className="title">
                  Nuestros <span>proyectos+</span>
                </h1>
                <div className="text-content">
                  <p>
                    Aportaremos calidad a tu Proyecto, desde la definición hasta
                    el cumplimiento de los plazos de entrega.
                  </p>
                  <p>
                    Ten la confianza que tu proyecto se mantendrá siempre de
                    forma confidencial y sólo será compartido entre nuestro
                    staff interno.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a href="img/projects.jpg" className="progressive replace">
                    <img
                      src="img/projectsblur.jpg"
                      className="preview"
                      alt="projects"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Portafolio
              </h2>
            </div>
            <div className="row">
              <div className="grid col-xs-12 col-md-10 col-offset-md-1 grid-md-1 grid-lg-2">
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="project-group"
                  >
                    <div className="project-video">
                      <Player
                        src="video/backusice.mp4"
                        poster="video/backusice.jpg"
                      />
                    </div>
                    <div className="project-group-content">
                      <h5>Backus Ice</h5>
                      <p>
                        Campañas creativas en playa y campo, para dispositivos
                        móviles y tótem publicitarios. soluciones especializadas
                        muy ligadas a la verticalización e hiperlocalización de
                        los usuarios, permiten promocionar tus productos y
                        segmentar la audiencia.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    className="project-group"
                  >
                    <div className="project-video">
                      <Player
                        src="video/semanaeconomica.mp4"
                        poster="video/semanaeconomica.jpg"
                      />
                    </div>
                    <div className="project-group-content">
                      <h5>Semana Económica</h5>
                      <p>
                        Revista desarrollada de forma nativa para todos los
                        dispositivos móviles iOS y Android. La aplicación SE
                        provee contenido de análisis económico y empresarial del
                        Perú.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="project-group"
                  >
                    <div className="project-video">
                      <Player
                        src="video/easypet.mp4"
                        poster="video/easypet.jpg"
                      />
                    </div>
                    {/* <figure className="center project-img">
                      <img src="http://placehold.it/600" alt="project"/>
                      <Link to="/" className="icon-play flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24"><desc>  Created with Sketch.</desc><g opacity="0.85"><g fill="#FE0033"><path d="M2 24C1.7 24 1.3 23.9 1 23.7 0.4 23.4 0 22.7 0 22L0 2C0 1.3 0.4 0.6 1 0.3 1.7-0.1 2.4-0.1 3.1 0.3L19.1 10.3C19.6 10.7 20 11.3 20 12 20 12.7 19.6 13.3 19.1 13.7L3.1 23.7C2.7 23.9 2.4 24 2 24"/></g></g></svg></Link>
                    </figure> */}
                    <div className="project-group-content">
                      <h5>Easypet</h5>
                      <p>
                        Website e-Commerce para ventas de productos y servicios.
                        Veterinaria Online 24x7 y tienda de accesorios para
                        mascotas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSeccion />
      </div>
    );
  }
}
