import React, { Component } from "react";

export default class BancosPage extends Component {
  componentDidMount() {
    document.title = "Cuentas XL+ | excellinked+";
  }

  render() {
    return (
      <div onLoad={this.props.loadPage} className="margin-footer">
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-offset-md-1">
                <h1 className="title">
                  Nuestras <span>cuentas XL Plus</span>
                </h1>
                <div className="text-content">
                  <p>
                    Hoy en día, la creación de empresas innovadoras son un factor importante para el desarrollo sostenible de cualquier negocio. Es por ello que creamos <b>XL Plus (excellinked+)</b> como una expansión del grupo empresarial <b>XL (excellinked)</b>.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a href="img/bancos.jpg" className="progressive replace">
                    <img
                      src="img/bancosblur.jpg"
                      className="preview"
                      alt="bancos"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-xs-1 col-xs-10">
                <div className="grid grid-md-2">
                  <div className="column">
                    {/* <hr /> */}
                    <div data-aos="fade-up" className="group-banco">
                      <div className="group-banco-title">
                        <p className="banco-bcp">
                          <b>BCP Banco de Crédito</b>
                        </p>
                      </div>
                      <div className="group-banco-content">
                        <div>
                          <p>
                           <b>AHORRO NEGOCIOS US$</b>
                          </p>
                          <p>19301046664135</p>
                        </div>
                        <div>
                          <p>
                            <b>CCI (US$)</b>
                          </p>
                          <p>002193101046664135-12</p>
                        </div>
                        <div>
                          <p>
                            <b>AHORRO NEGOCIOS S/</b>
                          </p>
                          <p>19301046652023</p>
                        </div>
                        <div>
                          <p>
                            <b>CCI (S/)</b>
                          </p>
                          <p>002193101046652023-14</p>
                      {/*   <p className="banco-bcp"> */}
                      {/*     <b>BCP</b> */}
                      {/*   </p> */}
                      {/* </div> */}
                      {/* <div className="group-banco-content"> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>AHORRO NEGOCIOS SOLES</b> */}
                      {/*     </p> */}
                      {/*     <p>19435877569066</p> */}
                      {/*   </div> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CCI (S/.)</b> */}
                      {/*     </p> */}
                      {/*     <p>00219413587756906693–20</p> */}
                      {/*   </div> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CUENTAS DÓLARES</b> */}
                      {/*     </p> */}
                      {/*     <p>19435926322112</p> */}
                      {/*   </div> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CCI (US$)</b> */}
                      {/*     </p> */}
                      {/*     <p>00219413592632211298–24</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    {/* <hr /> */}
                    <div data-aos="fade-up" className="group-banco">
                      <div className="group-banco-title">
                      {/*   <p className="banco-interbank"> */}
                      {/*     <b>Interbank</b> */}
                      {/*   </p> */}
                      {/* </div> */}
                      {/* <div className="group-banco-content"> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CUENTA CORRIENTE SOLES</b> */}
                      {/*     </p> */}
                      {/*     <p>200‑3001798411</p> */}
                      {/*   </div> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CCI (S/.)</b> */}
                      {/*     </p> */}
                      {/*     <p>003-200-003001798411-30</p> */}
                      {/*   </div> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CUENTAS DÓLARES</b> */}
                      {/*     </p> */}
                      {/*     <p>200‑3001798429</p> */}
                      {/*   </div> */}
                      {/*   <div> */}
                      {/*     <p> */}
                      {/*       <b>CCI (US$)</b> */}
                      {/*     </p> */}
                      {/*     <p>003-200-003001798429-32</p> */}

                      <p className="banco-interbank">
                          <b>Banco de la Nación</b>
                        </p>
                      </div>
                      <div className="group-banco-content">
                        <div>
                          <p>
                            <b>CUENTA DETRACCIONES</b>
                          </p>
                          <p>00-048-149529</p>
                        </div>
                        {/* /<div> */}
                        {/*   <p> */}
                        {/*     <b>Razón social: XL PLUS</b> */}
                        {/*   </p> */}
                        {/*   <p>RUC: 20607112445</p> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <div className="row">
                  <div className="grid grid-md-2 grid-xl-3">
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        Licenciatura Marca País:
                        <br />
                        1141-20447/PP/DPIP/institucional
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        Incorporación Buenos Contribuyentes por la Sunat:
                        <br /> Resolución N° 0230050134023
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        Incorporación Buenos Contribuyentes por la Sunat:
                        <br /> Resolución N° 0230050134023
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        Registro de Marca Indecopi:
                        <br /> Resolución N° 00081354
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        Socio Cámara de Comercio de Lima:
                        <br /> Nº 00031884.0
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        Código D-U-N-S® de D&B:
                        <br /> 670832431
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        OSCE – Bienes:
                        <br /> B0303928
                      </div>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <div className="group-otras-cuentas">
                        OSCE – Servicios:
                        <br /> S0710679
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
