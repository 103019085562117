import React, { Component } from 'react';
import { Map, Marker } from 'google-maps-react';

import { Formulario, InputGroup, TextArea, ButtonForm } from './../Form';

export default class ContactPage extends Component {
  constructor() {
    super();
    this.validation = this.validation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      formValid: {
        nombre: false,
        email: false,
        mensaje: false,
      },
      buttonInvalid: true,
    };
  }

  validation(field, valid) {
    let nameInput = field.target.name;
    let estado = this.state.formValid;
    estado[nameInput] = valid;
    let validar = Object.values(estado);
    let listo = 0;
    validar.forEach(valor => {
      if (valor) {
        return listo++;
      }
    });
    if (listo >= validar.length) {
      this.setState({
        buttonInvalid: false,
      });
    } else {
      this.setState({
        buttonInvalid: true,
      });
    }
    this.setState({
      formValid: estado,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let xmlhttp = new XMLHttpRequest();
    var name = document.querySelector('.input-name').value;
    var email = document.querySelector('.input-email').value;
    var message = document.querySelector('.input-mensaje').value;
    xmlhttp.open('POST', 'email.php', true);
    xmlhttp.setRequestHeader(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    xmlhttp.send('name=' + name + '&email=' + email + '&message=' + message);
    this.props.noAlert();
    setTimeout(() => {
      name = '';
      email = '';
      message = '';
    }, 1);
  }

  componentDidMount() {
    document.title = 'Contacto XL+ | excellinked';
  }

  render() {
    let mapStyle = [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ];
    return (
      <div onLoad={this.props.loadPage}>
        <section className="page-int-header page-int-contact">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1 col-xl-7 col-offset-xl-0">
                <img
                  data-aos="fade-up"
                  src="img/contactpage.jpg"
                  alt="contacto"
                />
              </div>
              <div className="col-xs-12 col-md-10 col-offset-md-1 col-xl-5 col-offset-xl-0">
                <div className="form-content">
                  <h1 className="title">
                    Comencemos a <span>hablar+</span>
                  </h1>
                  <div className="text-content">
                    <p>
                      ¡Da el primer paso y recibe tu cotización en menos de 48
                      horas hábiles!
                    </p>
                  </div>
                  <Formulario
                    className="form-contact"
                    method="post"
                    onSubmit={e => this.handleSubmit(e)}
                    autoComplete="off"
                    grid="grid grid-xs-1"
                  >
                    <InputGroup
                      type="text"
                      name="nombre"
                      required
                      label="Nombre"
                      pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                      title="Solo se aceptan letras"
                      validacion={this.validation}
                    />
                    <InputGroup
                      type="email"
                      name="email"
                      required
                      label="Email"
                      validacion={this.validation}
                    />
                    <TextArea
                      type="text"
                      name="mensaje"
                      minLength="10"
                      required
                      label="Cuéntanos como ayudarte"
                      validacion={this.validation}
                    />
                    <ButtonForm
                      className="btn-form link"
                      title="Quiero mi presupuesto"
                      icon="trending_flat"
                      disabled={this.state.buttonInvalid}
                    />
                  </Formulario>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="map">
          <Map
            google={window.google}
            disableDefaultUI={true}
            styles={mapStyle}
            draggable={true}
            zoom={15}
            maxZoom={15}
            initialCenter={{ lat: -12.099691, lng: -76.994957 }}
          >
            <Marker
              name={'excellinked'}
              position={{ lat: -12.099691, lng: -76.994957 }}
            />
          </Map>
        </section>
      </div>
    );
  }
}
