import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Formulario, InputGroup, TextArea, ButtonForm } from './../Form';

export default class HomePage extends Component {
  constructor() {
    super();
    this.validation = this.validation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      formValid: {
        nombre: false,
        email: false,
        mensaje: false,
      },
      buttonInvalid: true,
    };
  }

  validation(field, valid) {
    let nameInput = field.target.name;
    let estado = this.state.formValid;
    estado[nameInput] = valid;
    let validar = Object.values(estado);
    let listo = 0;
    validar.forEach(valor => {
      if (valor) {
        return listo++;
      }
    });
    if (listo >= validar.length) {
      this.setState({
        buttonInvalid: false,
      });
    } else {
      this.setState({
        buttonInvalid: true,
      });
    }
    this.setState({
      formValid: estado,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let xmlhttp = new XMLHttpRequest();
    var name = document.querySelector('.input-name').value;
    var email = document.querySelector('.input-email').value;
    var message = document.querySelector('.input-mensaje').value;
    xmlhttp.open('POST', 'email.php', true);
    xmlhttp.setRequestHeader(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    xmlhttp.send('name=' + name + '&email=' + email + '&message=' + message);
    this.props.noAlert(name);
    setTimeout(() => {
      name = '';
      email = '';
      message = '';
    }, 1);
  }

  componentDidMount() {
    document.title = 'Bienvenido a XL+ | excellinked+';
  }

  render() {
    return (
      <div onLoad={this.props.loadPage}>
        <div
          data-aos="fade-left"
          data-aos-delay="2000"
          data-aos-duration="600"
          className="col-xl-6 col-lg-5 clip-circle-contain"
        >
          <img src="img/home.jpg" alt="slide" className="clip-circle" />
        </div>
        <div className="back-1-circle" />
        <div
          data-aos="fade-down"
          data-aos-delay="1400"
          data-aos-duration="600"
          className="back-2-contain"
        >
          <div className="back-2-circle" />
        </div>
        <section id="home-slide">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-10 col-offset-md-1 col-xs-12">
              <h1
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="800"
                className="title"
              >
                Bienvenido al grupo <span>XL+ (excellinked+) :</span> <br />
                <span>Apps, Cloud & Automation</span>
              </h1>
              <p
                data-aos="fade-up"
                data-aos-delay="900"
                data-aos-duration="800"
              >
                TU PROYECTO+ : nuestro siguiente caso de éxito.
              </p>
              <Link to="/contacto" className="btn flex align-items-center">
                Quiero mi presupuesto{' '}
                <i className="material-icons animated">trending_flat</i>
              </Link>
              <div className="social-media flex align-items-center">
                <Link
                  className="icon-facebook flex align-items-center"
                  target="_blank"
                  to="https://www.facebook.com/excellinked"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="18"
                    viewBox="0 0 8 18"
                  >
                    <g fill="#AEABAB">
                      <path d="M8 5.6L5.3 5.6 5.3 3.8C5.3 3.1 5.7 3 6 3 6.3 3 8 3 8 3L8 0 5.3 0C2.4 0 1.7 2.2 1.7 3.6L1.7 5.6 0 5.6 0 8.6 1.7 8.6C1.7 12.5 1.7 17.2 1.7 17.2L5.3 17.2C5.3 17.2 5.3 12.5 5.3 8.6L7.7 8.6 8 5.6Z" />
                    </g>
                  </svg>
                </Link>
                <Link
                  className="icon-twitter flex align-items-center"
                  target="_blank"
                  to="https://twitter.com/excellinked"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                  >
                    <g fill="#AEABAB">
                      <path d="M18 1.7C17.3 2 16.6 2.2 15.9 2.3 16.6 1.9 17.2 1.1 17.5 0.3 16.8 0.7 16 1 15.2 1.2 14.5 0.4 13.5 0 12.5 0 10.4 0 8.8 1.7 8.8 3.7 8.8 4 8.8 4.3 8.9 4.5 5.8 4.4 3.1 2.9 1.3 0.7 0.9 1.2 0.8 1.9 0.8 2.5 0.8 3.8 1.4 4.9 2.4 5.6 1.8 5.6 1.2 5.4 0.7 5.1 0.7 5.2 0.7 5.2 0.7 5.2 0.7 7 2 8.5 3.7 8.8 3.4 8.9 3 8.9 2.7 8.9 2.5 8.9 2.2 8.9 2 8.9 2.5 10.3 3.9 11.4 5.5 11.4 4.2 12.4 2.6 13 0.9 13 0.6 13 0.3 13 0 13 1.6 14 3.6 14.6 5.7 14.6 12.5 14.6 16.2 9 16.2 4.1 16.2 4 16.2 3.8 16.2 3.6 16.9 3.1 17.5 2.5 18 1.7Z" />
                    </g>
                  </svg>
                </Link>
                <Link
                  className="icon-linkedin flex align-items-center"
                  target="_blank"
                  to="https://www.linkedin.com/company/excellinked"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                  >
                    <g fill="#AEABAB">
                      <polygon points="0.6 5.5 4.3 5.5 4.3 16.7 0.6 16.7" />
                      <path d="M2.5 4.1C1.2 4.1 0.4 3.2 0.4 2.1 0.4 1 1.2 0.2 2.5 0.2 3.8 0.2 4.5 1 4.6 2.1 4.6 3.2 3.7 4.1 2.5 4.1Z" />
                      <path d="M17.7 16.7L14 16.7 14 10.8C14 9.3 13.4 8.3 12.1 8.3 11 8.3 10.4 9 10.2 9.6 10.1 9.9 10.1 10.2 10.1 10.5L10.1 16.7 6.3 16.7C6.3 16.7 6.4 6.6 6.3 5.5L10.1 5.5 10.1 7.2C10.5 6.4 11.5 5.4 13.4 5.4 15.8 5.4 17.7 7 17.7 10.4L17.7 16.7Z" />
                    </g>
                  </svg>
                </Link>
                <Link
                  className="icon-whatsapp flex align-items-center"
                  to="https://api.whatsapp.com/send?phone=+51961355774"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                  >
                    <g fill="#AEABAB">
                      <path d="M15.4 2.8C13.7 1.1 11.4 0.2 9 0.2 4.1 0.2 0.1 4.2 0.1 9.1 0.1 10.7 0.5 12.2 1.3 13.6L0 18.2 4.8 17C6.1 17.7 7.5 18 9 18L9 18C9 18 9 18 9 18 14 18 18 14 18 9.1 18 6.7 17 4.5 15.4 2.8ZM9 16.5L9 16.5C7.7 16.5 6.4 16.2 5.3 15.5L5 15.3 2.2 16.1 2.9 13.3 2.7 13.1C2 11.9 1.6 10.5 1.6 9.1 1.6 5 4.9 1.7 9 1.7 11 1.7 12.9 2.4 14.3 3.9 15.7 5.3 16.5 7.1 16.5 9.1 16.5 13.2 13.1 16.5 9 16.5Z" />
                      <path d="M13.1 11C12.9 10.9 11.8 10.3 11.6 10.2 11.4 10.2 11.2 10.1 11.1 10.4 10.9 10.6 10.5 11.1 10.4 11.2 10.2 11.4 10.1 11.4 9.9 11.3 9.7 11.2 8.9 10.9 8.1 10.2 7.4 9.6 7 8.9 6.9 8.6 6.7 8.4 6.8 8.3 7 8.2 7.1 8.1 7.2 7.9 7.3 7.8 7.4 7.7 7.4 7.6 7.5 7.4 7.6 7.3 7.5 7.1 7.5 7 7.4 6.9 7 5.8 6.8 5.4 6.6 4.9 6.4 5 6.3 5 6.2 5 6 5 5.9 5 5.7 5 5.5 5 5.3 5.3 5.1 5.5 4.5 6 4.5 7.1 4.5 8.2 5.3 9.3 5.4 9.4 5.5 9.6 7 11.8 9.2 12.8 9.8 13 10.2 13.2 10.5 13.3 11 13.4 11.5 13.4 11.9 13.4 12.3 13.3 13.2 12.8 13.4 12.3 13.6 11.8 13.6 11.3 13.5 11.2 13.5 11.1 13.3 11.1 13.1 11Z" />
                    </g>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section id="home-services" className="container">
          <div className="row">
            <div
              data-aos="fade-up"
              className="col-xl-4 col-md-10 col-offset-md-1 col-xs-12"
            >
              <p className="title">
                Conduciremos tu Proyecto+ a la medida de tus necesidades.
              </p>
              <Link className="link link-accent" to="/servicios">
                más servicios{' '}
                <i className="material-icons animated">trending_flat</i>
              </Link>
            </div>
            <div className="col-xl-0 col-xs-12 margin-bottom-50" />
            <div className="col-xl-6 col-md-10 col-offset-md-1 col-xs-12">
              <div className="row">
                <div className="grid grid-md-2 services-group">
                  {/* <div className="column">
                    <div className="home-services-group" data-aos="fade-up" data-aos-delay="300">
                      <svg xmlns="http://www.w3.org/2000/svg" width="57.49" height="42" viewBox="0 0 57.49 34"><path fill="#ff2651" d="M56.88,31.26,49,25.31V1.52A1.52,1.52,0,0,0,47.43,0H10.07A1.52,1.52,0,0,0,8.55,1.52V25.31L.61,31.26A1.52,1.52,0,0,0,1.53,34H56a1.52,1.52,0,0,0,.92-2.74ZM11.6,3H45.9v21.5H11.6ZM6.1,31l4.48-3.35H46.92L51.39,31Z"/></svg>
                      <h5>Web Apps</h5>
                      <p>Abarcamos TODO: desde el diseño hasta el desarrollo. Así te garantizamos el cumplimiento de estándares y uso de últimas tecnologías.</p>
                    </div>
                  </div> */}
                  <div className="column">
                    <div
                      className="home-services-group"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="42"
                        viewBox="0 0 25 39"
                      >
                        <path
                          fill="#ff2651"
                          d="M20.84,0H4.16A4.23,4.23,0,0,0,0,4.3V34.7A4.23,4.23,0,0,0,4.16,39H20.84A4.23,4.23,0,0,0,25,34.7V4.3A4.23,4.23,0,0,0,20.84,0ZM4.16,3H20.84A1.25,1.25,0,0,1,22,4.3V6H3V4.3A1.25,1.25,0,0,1,4.16,3ZM22,9V27H3V9ZM20.84,36H4.16A1.25,1.25,0,0,1,3,34.7V30H22v4.7A1.25,1.25,0,0,1,20.84,36Z"
                        />
                        <circle fill="#ff2651" cx="12.5" cy="33" r="1.5" />
                      </svg>
                      <h5>Android+ & iOS+</h5>
                      <p>
                        Apps móviles y juegos desarrollados desde el UX hasta su
                        publicación y también con full mantenimiento y soporte.
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div
                      className="home-services-group"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="37"
                        height="42"
                        viewBox="0 0 37 37"
                      >
                        <path
                          fill="#ff2651"
                          d="M24.9,23.9h3c0.6,0,1.1-0.3,1.4-0.7c0.1-0.1,0.1-0.2,0.1-0.3l7.3-12.8c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2
                      	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1C37,9.1,37,9,37,9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
                      	c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0h-25l-1.1-6c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1C9.1,1.1,9.1,1,9,1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0H1.7C0.8,0.1,0,0.9,0,1.8s0.8,1.7,1.7,1.7h4.3l4.2,23.1c-1.7,0.9-2.8,2.7-2.8,4.8c0,3,2.5,5.5,5.5,5.5c2.4,0,4.5-1.6,5.2-3.8h6.3c0.7,2.2,2.8,3.8,5.2,3.8c3,0,5.5-2.5,5.5-5.5s-2.5-5.5-5.5-5.5c-2.4,0-4.5,1.6-5.2,3.8h-6.3c-0.7-1.9-2.4-3.4-4.4-3.7l-0.4-2.1H24.9z M29.6,29.4c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S28.5,29.4,29.6,29.4z M32.3,11l-5.4,9.5H12.7L10.9,11H32.3z M15,31.4c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S15,30.3,15,31.4z"
                        />
                      </svg>
                      <h5>e-Commerce+</h5>
                      <p>
                        Ofrecemos soluciones en e-Commerce, para impulsar a que
                        tus clientes reconozcan tu marca y se fidelicen.
                      </p>
                    </div>
                  </div>
                  {/* <div className="column">
                    <div className="home-services-group" data-aos="fade-up" data-aos-delay="400">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40.29" height="42" viewBox="0 0 40.28 40.44"><path fill="#ff2651" d="M33.83,2A41,41,0,0,0,20.14,0,41,41,0,0,0,6.46,2C4.86,2.61,0,4.34,0,8.16c0,0,0,.09,0,.13V20.12s0,.07,0,.1,0,.11,0,.17V32.18s0,.07,0,.1c0,5.36,10.13,8.16,20.14,8.16s20.14-2.8,20.14-8.16V8.16C40.28,4.34,35.42,2.61,33.83,2Zm3.46,18.18c0,2.1-6.68,5.16-17.14,5.16s-17-3-17.13-5.11V12.69a17.37,17.37,0,0,0,3.44,1.6,41,41,0,0,0,13.69,2,41,41,0,0,0,13.69-2,17.34,17.34,0,0,0,3.46-1.6ZM7.46,4.86A38.07,38.07,0,0,1,20.14,3,38.07,38.07,0,0,1,32.82,4.86c2.84,1,4.46,2.22,4.46,3.3s-1.63,2.28-4.46,3.3a38,38,0,0,1-12.68,1.86A38,38,0,0,1,7.46,11.46c-2.79-1-4.4-2.17-4.45-3.24V8.16s0,0,0,0C3,7.05,4.66,5.87,7.46,4.86ZM20.14,37.44c-10.41,0-17.06-3-17.14-5.13,0,0,0,0,0,0V24.72c3.75,2.41,10.46,3.66,17.13,3.66s13.4-1.25,17.14-3.67v7.57C37.28,34.39,30.61,37.44,20.14,37.44Z"/></svg>
                      <h5>Servidores</h5>
                      <p>Servidores de última generación, administrados por el propio equipo de XL que van de la mano con el mejor soporte técnico 24x7.</p>
                    </div>
                  </div> */}
                  <div className="column">
                    <div
                      className="home-services-group"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="42"
                        viewBox="0 0 39 41.33"
                      >
                        <polygon
                          points="19.5 1.5 1.5 15.35 1.5 39.83 37.5 39.83 37.5 15.35 19.5 1.5"
                          fill="white"
                          stroke="#ff2651"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2.995488405227661px"
                        />
                        <rect
                          x="13.57"
                          y="20.21"
                          width="11.86"
                          height="11.86"
                          fill="white"
                          stroke="#ff2651"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2.995488405227661px"
                        />
                      </svg>
                      <h5>Trabajos+ de infraestructura</h5>
                      <p>
                        Instalamos cámaras de seguridad, datacenter y equipos de
                        telecomunicaciones. Ademas brindamos servicios de
                        Hosting y Cloud de alto performance y seguridad.
                        Automation y domótica en general.
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div
                      className="home-services-group"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="42"
                        viewBox="0 0 40 28"
                      >
                        <path
                          d="M39.88,23.08,35.65,8A1.3,1.3,0,0,0,34.4,7H30.11a1.3,1.3,0,1,0,0,2.61h3.3L37,22.31H3L6.58,9.61h3.3A1.3,1.3,0,1,0,9.87,7H5.58A1.3,1.3,0,0,0,4.33,8L0,23.26v0a1.3,1.3,0,0,0,0,.18s0,.07,0,.1v2.16a8.63,8.63,0,0,0,4.58,7.94A9.1,9.1,0,0,0,9.16,35a9.1,9.1,0,0,0,4.58-1.32,8.63,8.63,0,0,0,4.58-7.94v-.83h3.34v.83a9.16,9.16,0,0,0,9.16,9.16A9.17,9.17,0,0,0,40,25.74V23.61A1.3,1.3,0,0,0,39.88,23.08ZM15.72,25.75a6.1,6.1,0,0,1-3.28,5.68,6.1,6.1,0,0,1-6.56,0,6.1,6.1,0,0,1-3.28-5.68v-.83H15.72Zm19.75,4.63a6.55,6.55,0,0,1-9.27,0,6.26,6.26,0,0,1-1.92-4.64v-.83H37.39v.83A6.26,6.26,0,0,1,35.47,30.38Z"
                          transform="translate(0 -7)"
                          fill="#ff2651"
                        />
                      </svg>
                      <h5>Realidad+ Mixta</h5>
                      <p>
                        Es la evolución de la Realidad Aumentada. Creamos juegos
                        y aplicaciones añadiendo los beneficios de la Realidad
                        Virtual.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="home-contact">
          <div className="container">
            <div className="row">
              <div className="form-contact col-xl-5 col-offset-xl-7 col-lg-5 col-offset-lg-6 col-md-10 col-offset-md-1 col-xs-12">
                <div data-aos="fade-up" className="form-title">
                  <p className="title">
                    <span>¿Cómo podemos ayudarte?</span>
                  </p>
                  <p className="title">¡Hagamos algo genial+ !</p>
                </div>
                <Formulario
                  method="post"
                  onSubmit={e => this.handleSubmit(e)}
                  autoComplete="off"
                  grid="grid grid-xs-1"
                >
                  <InputGroup
                    type="text"
                    name="nombre"
                    required
                    label="Nombre"
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                    title="Solo se aceptan letras"
                    validacion={this.validation}
                  />
                  <InputGroup
                    type="email"
                    name="email"
                    required
                    label="Email"
                    validacion={this.validation}
                  />
                  <TextArea
                    type="text"
                    name="mensaje"
                    minLength="10"
                    required
                    label="Cuéntanos como ayudarte"
                    validacion={this.validation}
                  />
                  <ButtonForm
                    className="btn-form link"
                    title="enviar"
                    icon="trending_flat"
                    disabled={this.state.buttonInvalid}
                  />
                </Formulario>
              {/* <div className="row">
                  <div className="contact-social col-xs-12 flex align-items-center">
                    <Link
                      className="contact-messenger"
                      to="https://m.me/excellinked"
                      target="_blank"
                    >
                      Escríbenos por messenger
                    </Link>
                    <Link
                      className="contact-whatsapp"
                      to="https://api.whatsapp.com/send?phone=+51961355774"
                      target="_blank"
                    >
                      Escríbenos por whatsapp
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
