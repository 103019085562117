import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "./xlplus.svg";
import logoxl from "./excellinkedplus.svg";

class Header extends Component {
  constructor() {
    super();
    this.mostarMenu = this.mostarMenu.bind(this);
    this.ocultarMenu = this.ocultarMenu.bind(this);
  }

  mostarMenu() {
    let menu = document.getElementsByTagName("nav")[0];
    let menuActive = menu.classList.value.indexOf("menuActivo");
    if (menuActive > -1) {
      menu.classList.remove("menuActivo");
    } else {
      menu.classList.add("menuActivo");
    }
  }

  ocultarMenu() {
    let menu = document.getElementsByTagName("nav")[0];
    menu.classList.remove("menuActivo");
  }

  render() {
    return (
      <header className="flex align-items-center">
        <div className="logos" onClick={this.ocultarMenu}>
          <Link className="logotipo" to="/">
            <img src={logo} alt="excellinked" />
          </Link>
          <Link className="logotipo" to="/">
            <img src={logoxl} alt="excellinked" />
          </Link>
        </div>
        <nav className="flex align-items-center">
          <li onClick={this.mostarMenu}>
            <NavLink exact to="/" activeClassName="nav-active">
              Inicio
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/nosotros" activeClassName="nav-active">
              Nosotros
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/xl-plus" activeClassName="nav-active">
              XL+
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/servicios" activeClassName="nav-active">
              Servicios
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/proyectos" activeClassName="nav-active">
              Proyectos
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/servidores" activeClassName="nav-active">
              Servidores
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/domotica" activeClassName="nav-active">
              Domótica
            </NavLink>
          </li>
          <li onClick={this.mostarMenu}>
            <NavLink to="/contacto" activeClassName="nav-active">
              Contacto
            </NavLink>
          </li>
        </nav>
        <button onClick={this.mostarMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="21"
            viewBox="0 0 32 21"
          >
            <g fill="none">
              <g fill="#404244">
                <rect x="14" y="18" width="18" height="3" rx="1.5" />
                <rect x="6" y="9" width="26" height="3" rx="1.5" />
                <rect width="32" height="3" rx="1.5" />
              </g>
            </g>
          </svg>
        </button>
      </header>
    );
  }
}

export default Header;
