import React, { Component } from "react";
import { RouteTransition } from "react-router-transition";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import firebase from "firebase";
import "../node_modules/aos/dist/aos.css";
import AOS from "aos";
import request from "request";

import Header from "./Header";
import Footer from "./Footer";

import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import XLPlusPage from "./pages/xlplus";
import ServicePage from "./pages/service";
import ProjectPage from "./pages/project";
import ServidoresPage from "./pages/servers";
import DomoticaPage from "./pages/domotica";
import MixedReality from "./pages/mixed-reality";
import ContactPage from "./pages/contact";
import LegalesPage from "./pages/legal";
import BancosPage from "./pages/banks";
import BancosXLplusPage from "./pages/bankxlplus";
import MiIp from "./pages/ip";
import NoMatch from "./pages/error";

import { Formulario, InputGroup, ButtonForm } from "./Form";

import "./App.scss";

let Culqi = window.Culqi;
const baseUrl = "https://api.culqi.com/v2";
const paths = {
  createCharge: "/charges",
  getCharge: "/charges",
};

/* window.culqi = function culqi() {
  if (Culqi.token) {
    let data = {
      amount: Culqi.culqi_data__settings.amount,
      currency_code: Culqi.culqi_data__settings.currency,
      email: Culqi.token.email,
      source_id: Culqi.token.id,
    };

    fetch('https://api.culqi.com/v2/charges', {
      method: 'POST',
      header: {
        'Content-type': 'application/json',
        Authorization: 'Bearer sk_live_DIWqebwBZzPrNCeq',
      },
      body: JSON.stringify(data),
    })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  } else {
    console.log(Culqi.error);
  }
}; */

const createPromise = (url, method, headers, body, validateParams) => {
  return new Promise((resolve, reject) => {
    if (validateParams && body) {
      const keys = Object.keys(body);
      for (let i in validateParams) {
        if (keys.indexOf(validateParams[i]) === -1) {
          return reject({
            body: {
              object: "error",
              type: "Invalid parameters: " + validateParams[i],
              code: "422",
            },
          });
        }
      }
    }
    // return fetch(url, {
    //   method: method,
    //   headers: headers,
    //   body: JSON.stringify(body)
    // }).then(response => {
    //   if(response.ok) {
    //     console.log('Compra exitosa XL');
    //     return resolve(response);
    //   } else {
    //     console.log('Error en la compra XL');
    //     return resolve(response);
    //   }
    // }).catch(error => {
    //   console.log('Error catch en la compra XL');
    //   return reject(error);
    // })
    return request(
      { url, method, headers, json: true, body },
      (error, response) => {
        if (error) {
          return reject(error);
        }
        return resolve(response);
      }
    );
  });
};

class culqiXL {
  constructor(keyPublic, keyPrivate) {
    this.keyPublic = keyPublic;
    this.keyPrivate = keyPrivate;
    this.baseUrl = baseUrl;
    this.headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${this.keyPrivate}`,
    };
  }

  createCharge(params) {
    const url = this.baseUrl + paths.createCharge;
    const fields = [
      "amount",
      "currency_code",
      "description",
      "email",
      "antifraud_details",
      "source_id",
    ];
    return createPromise(url, "POST", this.headers, params, fields);
  }

  // getCharge(params){
  // 	var url = this.baseUrl + paths.getCharge + '/' + (params || {}).id;
  // 	var fields = [
  // 		'id'
  // 	];
  // 	return createPromise(url, 'GET', this.headers, params, fields);
  // }
}

class Renovacion extends Component {
  constructor(props) {
    super(props);
    this.validation = this.validation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.actualizarDatos = this.actualizarDatos.bind(this);
    this.state = {
      moneda: "USD",
      tipoCambio: 3.27,
      buttonInvalid: true,
      cliente: [],
      formValid: [],
      popup: false,
      typePopup: "okey",
      mensajeError: "Lo sentimos, se ha detecto un error.",
    };
  }

  validation(field, valid) {
    let nameInput = field.target.name;
    let estado = this.state.formValid;
    estado[nameInput] = valid;
    let validar = Object.values(estado);
    let listo = 0;
    validar.forEach((valor) => {
      if (valor) {
        return listo++;
      }
    });
    if (listo >= validar.length) {
      this.setState({
        buttonInvalid: false,
      });
    } else {
      this.setState({
        buttonInvalid: true,
      });
    }
    this.setState({
      formValid: estado,
    });
  }

  actualizarDatos(name, value) {
    let client = this.state.cliente;
    client[name] = value;
    this.setState({
      cliente: client,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    // let xmlhttp = new XMLHttpRequest();
    // let frm = document.querySelector('.form-pay');
    let name = document.querySelector(".input-nombre").value;
    let apellido = document.querySelector(".input-apellido").value;
    let telefono = document.querySelector(".input-telefono").value;
    let email = document.querySelector(".input-email").value;
    let plan = document.querySelector(".input-plan").value;
    let dominio = document.querySelector(".input-dominio").value;

    let cambio = this.state.tipoCambio;
    let moneda = this.state.moneda;

    let precioHosting;
    if (this.state.cliente.priceHosting)
      precioHosting = this.state.cliente.priceHosting;
    else precioHosting = 0;
    let precioDominio;
    if (this.state.cliente.priceDominio)
      precioDominio = this.state.cliente.priceDominio;
    else precioDominio = 0;
    let subtotal = precioHosting + precioDominio;

    let total;
    if (moneda === "USD") total = subtotal * 1.18 * 100;
    else if (moneda === "PEN") total = subtotal * cambio * 1.18 * 100;

    Culqi.settings({
      title: "Servidores XL",
      currency: moneda,
      description: "Renovación de Hosting y/o Dominio",
      amount: total,
    });
    setTimeout(() => {
      Culqi.open();
    }, 1);
    setTimeout(() => {
      let self = this;
      window.culqi = () => {
        if (Culqi.token) {
          let token = Culqi.token;
          let culqi = new culqiXL(
            "pk_live_MeCSSNoPBDnCWo9H",
            "sk_live_DIWqebwBZzPrNCeq"
          );
          culqi
            .createCharge({
              amount: total,
              currency_code: moneda,
              description: `${plan} - ${dominio}`,
              email: email,
              antifraud_details: {
                country_code: "PE",
                first_name: name,
                last_name: apellido,
                address: "No identificada",
                address_city: "No identificada",
                phone_number: telefono,
              },
              source_id: token.id,
            })
            .then((response) => {
              if (response.statusCode === 200 || response.statusCode === 201) {
                self.setState({
                  typePopup: "okey",
                  popup: true,
                });
                // frm.reset();
              } else {
                self.setState({
                  mensajeError: response.body.user_message,
                  typePopup: "error",
                  popup: true,
                });
              }
            });
        } else {
          console.log(Culqi.error);
          alert(Culqi.error.mensaje);
        }
      };
    }, 1);
    // const itemsRef = firebase.database().ref('clientes');
    // for (let cliente in clientes) {
    //   itemsRef.push(clientes[cliente]);
    // }
  }

  componentDidMount() {
    document.title = "Renovación de plan | excellinked";
    const itemsRef = firebase.database().ref("clientes");
    itemsRef.on("value", (snapshot) => {
      let items = snapshot.val();
      for (let item in items) {
        if (item === this.props.params.id) {
          let datosVerificados = {
            nombre: Boolean(items[item].nombre),
            apellido: Boolean(items[item].apellido),
            telefono: Boolean(items[item].telefono),
            email: Boolean(items[item].email),
            plan: true,
            dominio: true,
            terminos: false,
          };
          this.setState({
            cliente: items[item],
            formValid: datosVerificados,
          });
          this.props.loadPage();
        }
      }
    });
  }

  render() {
    let moneda = this.state.moneda;
    return (
      <div>
        <section className="container">
          <div className="row">
            <div className="form-renovacion col-xs-10 col-offset-xs-1 col-md-8 col-offset-md-2">
              <h2 className="subtitle">Renovación</h2>
              <Formulario
                method="post"
                onSubmit={(e) => this.handleSubmit(e)}
                autoComplete="off"
                className="form-pay"
                grid="grid grid-md-2"
              >
                <InputGroup
                  activo
                  type="text"
                  value={this.state.cliente.nombre}
                  nuevoValor={this.actualizarInfo}
                  name="nombre"
                  required
                  label="Nombre"
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                  title="Solo se aceptan letras"
                  validacion={this.validation}
                  onChange={this.actualizarDatos}
                />
                <InputGroup
                  activo
                  type="text"
                  value={this.state.cliente.apellido}
                  name="apellido"
                  required
                  label="Apellido"
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                  title="Solo se aceptan letras"
                  validacion={this.validation}
                  onChange={this.actualizarDatos}
                />
                <InputGroup
                  type="number"
                  name="telefono"
                  required
                  label="Teléfono"
                  minLength="7"
                  validacion={this.validation}
                  onChange={this.actualizarDatos}
                />
                <InputGroup
                  activo
                  type="email"
                  value={this.state.cliente.email}
                  name="email"
                  required
                  label="Email"
                  validacion={this.validation}
                  onChange={this.actualizarDatos}
                />
                <InputGroup
                  activo
                  disabled
                  type="text"
                  value={
                    this.state.cliente.priceHosting
                      ? `${
                          moneda === "USD"
                            ? `${this.state.cliente.plan} - $${this.state.cliente.priceHosting} + IGV`
                            : `Soles`
                        }`
                      : `No tienes hosting contratado.`
                  }
                  name="plan"
                  required
                  label="Plan"
                  validacion={this.validation}
                />
                <InputGroup
                  activo
                  disabled
                  type="text"
                  value={
                    this.state.cliente.priceDominio
                      ? `${
                          moneda === "USD"
                            ? `Tienes ${this.state.cliente.nDominios} dominio - $${this.state.cliente.priceDominio} + IGV`
                            : `Soles`
                        }`
                      : `No tienes dominio contratado.`
                  }
                  name="dominio"
                  required
                  label="Dominio"
                  validacion={this.validation}
                />
                <div>
                  <input
                    name="terminos"
                    onChange={(e) =>
                      this.validation(e, !this.state.formValid.terminos)
                    }
                    required
                    type="checkbox"
                  />{" "}
                  <span>
                    Acepto los{" "}
                    <a href="/" target="_blank">
                      términos y condiciones
                    </a>
                  </span>
                </div>
                <ButtonForm
                  className="btn-form link"
                  title="renovar"
                  icon="trending_flat"
                  disabled={this.state.buttonInvalid}
                />
              </Formulario>
            </div>
          </div>
        </section>
        {this.state.popup && (
          <div className="alert flex align-items-center">
            <div className="content-alert">
              <h6>
                {this.state.typePopup === "okey"
                  ? `¡Renovación exitosa!`
                  : `¡Hubo un error!`}
              </h6>
              <p>
                {this.state.typePopup === "okey"
                  ? `La renovación fue realizada correctamente, en breve nos estaremos comunicando contigo.`
                  : `${this.state.mensajeError}`}
              </p>
              <button
                className="btn"
                onClick={() => this.setState({ popup: false })}
              >
                Listo
              </button>
            </div>
          </div>
        )}
      </div>
      // <NoMatch loadPage={this.props.loadPage}/>
    );
  }
}

class App extends Component {
  constructor() {
    super();
    this.hideAlert = this.hideAlert.bind(this);
    this.hidePago = this.hidePago.bind(this);
    this.hideError = this.hideError.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.showBody = this.showBody.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.crearCliente = this.crearCliente.bind(this);
    this.state = {
      loading: false,
      loadedImg: false,
      showAlerta: false,
      nameContact: "",
      showPago: false,
      showError: false,
      mensajeError: "",
    };
  }

  hideAlert(name) {
    if (typeof name === "string") {
      let onlyName = name.split(" ");
      let soloNombre = onlyName[0];
      this.setState({
        showAlerta: !this.state.showAlerta,
        nameContact: soloNombre,
      });
    } else {
      this.setState({
        showAlerta: !this.state.showAlerta,
        nameContact: "",
      });
    }
  }

  hidePago() {
    this.setState({
      showPago: false,
    });
  }

  hideError() {
    this.setState({
      showError: false,
    });
  }

  handleImageLoaded() {
    this.setState({ loadedImg: true });
    setTimeout(() => {
      if (this.state.loadedImg && this.state.loading) {
        this.showBody();
      }
    }, 1);
    AOS.init();
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
    // start
    var pItem = document.getElementsByClassName("progressive replace");
    loadFullImage(pItem[0]);
    if (pItem[0]) pItem[0].classList.remove("replace");
    // replace with full image
    function loadFullImage(item) {
      if (!item || !item.href) return;
      // load image
      var img = new Image();
      if (item.dataset) {
        img.srcset = item.dataset.srcset || "";
        img.sizes = item.dataset.sizes || "";
      }
      img.src = item.href;
      img.className = "reveal";
      if (img.complete) addImg();
      else img.onload = addImg;
      // replace image
      function addImg() {
        // disable click
        item.addEventListener(
          "click",
          function (e) {
            e.preventDefault();
          },
          false
        );
        // add full image
        item.appendChild(img).addEventListener("animationend", function (e) {
          // remove preview image
          var pImg = item.querySelector && item.querySelector("img.preview");
          if (pImg) {
            e.target.alt = pImg.alt || "";
            item.removeChild(pImg);
            e.target.classList.remove("reveal");
          }
        });
      }
    }
  }

  showBody() {
    let loader = document.getElementById("loader");
    let x = document.getElementsByTagName("html")[0];
    x.style.overflowY = "auto";
    loader.classList.add("loading");
    setTimeout(() => {
      loader.style.zIndex = "-100";
    }, 250);
  }

  scrollTo() {
    let body = document.getElementsByTagName("body")[0];
    body.scrollTop = 0;
  }

  crearCliente(moneda, cambio) {
    let xmlhttp = new XMLHttpRequest();
    let frm = document.querySelector(".form-pay");
    let name = document.querySelector(".input-nombre").value;
    let apellido = document.querySelector(".input-apellido").value;
    let empresa = document.querySelector(".input-empresa").value;
    let documento = document.querySelector(".input-documento").value;
    let direccion = document.querySelector(".input-direccion").value;
    let distrito = document.querySelector(".input-distrito").value;
    let ciudad = document.querySelector(".input-ciudad").value;
    let pais = document.querySelector(".input-pais").value;
    let telefono = document.querySelector(".input-telefono").value;
    let email = document.querySelector(".input-email").value;
    let plan = document.querySelector(".input-plan").value;
    let dominio = document.querySelector(".input-dominio").value;
    let message = `
      Nombre: ${name},
      Apellido: ${apellido},
      Empresa: ${empresa},
      Documento: ${documento},
      Direccción: ${direccion},
      Distrito: ${distrito},
      Ciudad: ${ciudad},
      País: ${pais},
      Teléfono: ${telefono},
      Email: ${email},
      Plan: ${plan},
      Dominio: ${dominio}
    `;
    var precio;
    if (moneda === "USD" && plan === "Hosting - XL Startup")
      precio = 3400 * 1.18;
    else if (moneda === "PEN" && plan === "Hosting - XL Startup")
      precio = 3400 * cambio * 1.18;
    else if (moneda === "USD" && plan === "Hosting - XL Senior")
      precio = 5600 * 1.18;
    else if (moneda === "PEN" && plan === "Hosting - XL Senior")
      precio = 5600 * cambio * 1.18;
    else if (moneda === "USD" && plan === "Hosting - XL Pro")
      precio = 7500 * 1.18;
    else if (moneda === "PEN" && plan === "Hosting - XL Pro")
      precio = 7500 * cambio * 1.18;
    else if (moneda === "USD" && plan === "Hosting - XL Ultimate")
      precio = 9600 * 1.18;
    else if (moneda === "PEN" && plan === "Hosting - XL Ultimate")
      precio = 9600 * cambio * 1.18;
    else if (moneda === "USD" && plan === "Cloud - XL Startup")
      precio = 7500 * 1.18;
    else if (moneda === "PEN" && plan === "Cloud - XL Startup")
      precio = 7500 * cambio * 1.18;
    else if (moneda === "USD" && plan === "Cloud - XL Senior")
      precio = 8800 * 1.18;
    else if (moneda === "PEN" && plan === "Cloud - XL Senior")
      precio = 8800 * cambio * 1.18;
    else if (moneda === "USD" && plan === "Cloud - XL Pro")
      precio = 10000 * 1.18;
    else if (moneda === "PEN" && plan === "Cloud - XL Pro")
      precio = 10000 * cambio * 1.18;

    xmlhttp.open("POST", "servidor.php", true);
    xmlhttp.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    xmlhttp.send("name=" + name + "&email=" + email + "&message=" + message);
    Culqi.settings({
      title: "Servidores XL",
      currency: moneda,
      description: "Plan " + plan,
      amount: precio,
    });
    setTimeout(() => {
      Culqi.open();
    }, 1000);
    setTimeout(() => {
      let self = this;
      window.culqi = () => {
        if (Culqi.token) {
          let token = Culqi.token;
          let culqi = new culqiXL(
            "pk_live_MeCSSNoPBDnCWo9H",
            "sk_live_DIWqebwBZzPrNCeq"
          );
          culqi
            .createCharge({
              amount: precio,
              currency_code: "USD",
              description: plan,
              email: email,
              antifraud_details: {
                address: direccion,
                address_city: ciudad,
                country_code: pais,
                first_name: name,
                last_name: apellido,
                phone_number: telefono,
              },
              source_id: token.id,
            })
            .then((response) => {
              if (response.statusCode === 200 || response.statusCode === 201) {
                self.setState({
                  showPago: true,
                });
                frm.reset();
              } else {
                self.setState({
                  mensajeError: response.body.user_message,
                  showError: true,
                });
              }
            });
        } else {
          console.log(Culqi.error);
          alert(Culqi.error.mensaje);
        }
      };
    }, 1);
  }

  componentDidMount() {
    this.setState({ loading: true });
    Culqi.publicKey = "pk_live_MeCSSNoPBDnCWo9H";
    setTimeout(() => {
      if (this.state.loadedImg && this.state.loading) {
        this.showBody();
      }
    }, 1);
  }

  render() {
    return (
      <Router>
        <Route
          render={({ location }) => {
            window.scrollTo(0, 0);
            return (
              <div>
                <Header />
                <RouteTransition
                  pathname={location.pathname}
                  atEnter={{ opacity: 0 }}
                  atLeave={{ opacity: 0 }}
                  atActive={{ opacity: 1 }}
                >
                  <Switch key={location.key} location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <HomePage
                          noAlert={this.hideAlert}
                          loadPage={this.handleImageLoaded}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/nosotros"
                      render={() => (
                        <AboutPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/xl-plus"
                      render={() => (
                        <XLPlusPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/servicios"
                      render={() => (
                        <ServicePage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/proyectos"
                      render={() => (
                        <ProjectPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/servidores"
                      render={() => (
                        <ServidoresPage
                          client={this.crearCliente}
                          loadPage={this.handleImageLoaded}
                        />
                      )}
                    />
                   <Route
                      exact
                      path="/bancos"
                      render={() => (
                        <BancosPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                   <Route
                      exact
                      path="/bancosplus"
                      render={() => (
                        <BancosXLplusPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/legales"
                      render={() => (
                        <LegalesPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/domotica"
                      render={() => (
                        <DomoticaPage loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/realidad-mixta"
                      render={() => (
                        <MixedReality loadPage={this.handleImageLoaded} />
                      )}
                    />
                    <Route
                      exact
                      path="/contacto"
                      render={() => (
                        <ContactPage
                          noAlert={this.hideAlert}
                          loadPage={this.handleImageLoaded}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/miip"
                      render={() => <MiIp loadPage={this.handleImageLoaded} />}
                    />
                    <Route
                      path="/renovacion/:id"
                      render={({ match }) => (
                        <Renovacion
                          params={match.params}
                          loadPage={this.handleImageLoaded}
                        />
                      )}
                    />
                    <Route
                      render={() => (
                        <NoMatch loadPage={this.handleImageLoaded} />
                      )}
                    />
                  </Switch>
                </RouteTransition>
                <Footer />
                <button
                  onClick={this.scrollTo}
                  data-aos="fade-up"
                  data-aos-anchor="#footer-anchor"
                  className="toTop"
                >
                  <i className="material-icons">arrow_upward</i>
                </button>
                {this.state.showAlerta && (
                  <div className="alert flex align-items-center">
                    <div className="content-alert">
                      <h6>¡Mensaje enviado!</h6>
                      <p>
                        Gracias {this.state.nameContact}, te estaremos
                        respondiendo en breve.
                      </p>
                      <button className="btn" onClick={this.hideAlert}>
                        Listo
                      </button>
                    </div>
                  </div>
                )}
                {this.state.showPago && (
                  <div className="alert flex align-items-center">
                    <div className="content-alert">
                      <h6>¡Compra exitosa!</h6>
                      <p>
                        El pago fue realizado correctamente, y en breve nos
                        estaremos comunicando contigo.
                      </p>
                      <button className="btn" onClick={this.hidePago}>
                        Listo
                      </button>
                    </div>
                  </div>
                )}
                {this.state.showError && (
                  <div className="alert flex align-items-center">
                    <div className="content-alert">
                      <h6>¡Hubo un error!</h6>
                      <p>{this.state.mensajeError}</p>
                      <button className="btn" onClick={this.hideError}>
                        Listo
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        />
      </Router>
    );
  }
}

export default App;
