import React, { Component } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  color: #8D9398;
  transition: all .25s ease;
  z-index: 1;
`;
//
const FormGroup = styled.div`
  position: relative;
  width: 100%;
  label,input,textarea,select {
    display: block;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 1.8em;
    padding-left: 10px;
    line-height: 24px;
    position: relative;
    outline: none;
    box-shadow: none;
  }
  label {
    top: ${props => props.active ? '0' : '27px'};
    font-size: ${props => props.active ? '1.6em' : '' };
  }
  input {
  	border: none;
  	background-color: rgba(0,0,0,0);
  	color: #404244;
  	font-weight: 400;
  	padding: 5px 10px 15px;
  	z-index: 5;
  }
  textarea {
    padding: 5px 10px 15px;
    border: none;
    outline: none;
    background-color: transparent;
    color: $color-general;
    resize: none;
    height: 150px;
    z-index: 5;
  }
  select {
    background-color: #fff;
    border: none;
    padding: 5px 25px 15px 10px;
    width: calc(100% - 3px);
    color: #404244;
    appearance: none;
    position: relative;
    + i {
      position: absolute;
      right: 0;
      bottom: 13px;
    }
  }
  &::after, &::before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: #DEE4E8;
    bottom: 0;
    top: auto;
    z-index: 1;
  }
  &::before {
    left: 0;
    right: 0;
  }
  &::after {
    left: 0;
    transition: all .25s ease-in;
    right: ${props => props.active ? '0' : '100%'};
    background-color: #404244;
    z-index: 2;
  }
`;

export class InputGroup extends Component {
  constructor(props) {
    super(props);
    this.inputActivo = this.inputActivo.bind(this);
    this.inputDesactivado = this.inputDesactivado.bind(this);
    //this.actualizarDato = this.actualizarDato.bind(this);
    this.state = {
      error: "",
      active: false
    }
  }

  inputActivo(e) {
    this.setState({
      active: true
    });
    //let nameInput = this.props.name;
    //nameInput.classList.add('active');
    //let nameInput = e.target.name;
    //this.refs[nameInput].classList.add('active');
  }

  showError(field, errorMessage) {
    //this.inputActivo(e);
    //console.log(this.render.errorMensaje);
    this.setState({
      error: errorMessage
    });
  }

  buscarError(field) {
    if( field.value === "" ) {
      this.setState({
        active: false
      });
    }
    let validity = field.validity;
    if ( this.props.type === "number" && field.value.length < this.props.minLength ) {
      return 'Por favor ingresa un número mínino de ' + this.props.minLength + ' dígitos.'
    }
    if ( this.props.type === "number" && field.value.length > this.props.maxLength ) {
      return 'Por favor ingresa un número máximo de ' + this.props.maxLength + ' dígitos.'
    }
    if (validity.valid) return;
    // If field is required and empty
    if (validity.valueMissing) return 'Este campo es obligatorio.';
    // If not the right type
    if (validity.typeMismatch) {
        // Email
        if (field.type === 'email') return 'Por favor ingresa un correo electrónico.';
        // URL
        if (field.type === 'url') return 'Por favor ingresa una URL.';
    }
    // If too short
    if (validity.tooShort) return 'Please lengthen this text to ' + field.getAttribute('minLength') + ' characters or more. You are currently using ' + field.value.length + ' characters.';
    // If too long
    if (validity.tooLong) return 'Please shorten this text to no more than ' + field.getAttribute('maxLength') + ' characters. You are currently using ' + field.value.length + ' characters.';
    // If number input isn't a number
    if (validity.badInput) return 'Por favor ingresa un número.';
    // If a number value doesn't match the step interval
    if (validity.stepMismatch) return 'Por favor selecciona un valor válido.';
    // If a number field is over the max
    if (validity.rangeOverflow) return 'Please select a value that is no more than ' + field.getAttribute('max') + '.';
    // If a number field is below the min
    if (validity.rangeUnderflow) return 'Please select a value that is no less than ' + field.getAttribute('min') + '.';
    // If pattern doesn't match
    if (validity.patternMismatch) {
        // If pattern info is included, return custom error
        if (field.hasAttribute('title')) return field.getAttribute('title');
        // Otherwise, generic error
        return 'Por favor ingresa un formato válido.';
    }
    // If all else fails, return a generic catchall error
    return 'El valor ingresado en este campo es inválido.';
  }

  inputDesactivado(e) {
    let errorMessage = this.buscarError(e.target);
    if( errorMessage ) {
      this.showError(e.target, errorMessage);
      //e.target.classList.remove('valid');
      this.props.validacion(e, false);
    } else {
      this.setState({
        error: ""
      });
      this.props.validacion(e, true);
      //e.target.classList.add('valid');
    }
  }

  /* actualizarDato(e) {
    this.props.onChange(e.target.name, e.target.value)
  } */

  componentWillMount() {
    if(this.props.activo) {
      this.setState({
        active: true
      })
    }
  }

  render() {
    return (
      <FormGroup active={this.state.active} >
        {this.props.label &&
          <Label>{this.props.label}</Label>
        }
        <input disabled={this.props.disabled} value={this.props.value} className={'input-'+this.props.name} type={this.props.type} minLength={this.props.minLength} maxLength={this.props.maxLength} required={this.props.required} pattern={this.props.pattern} title={this.props.title} name={this.props.name} onFocus={this.inputActivo} onBlur={this.inputDesactivado} />
        {this.state.error.length > 0 &&
          <div className="error-message">
            { this.state.error }
          </div>
        }
      </FormGroup>
    )
  }
}

export class TextArea extends Component {
  constructor() {
    super();
    this.inputActivo = this.inputActivo.bind(this);
    this.inputDesactivado = this.inputDesactivado.bind(this);
    this.state = {
      error: "",
      active: false
    }
  }

  inputActivo(e) {
    this.setState({
      active: true
    });
  }

  showError(field, errorMessage) {
    this.setState({
      error: errorMessage
    });
  }

  buscarError(field) {
    if( field.value === "" ) {
      this.setState({
        active: false
      });
    }
    let validity = field.validity;
    if (validity.valid) return;
    // If field is required and empty
    if (validity.valueMissing) return 'Este campo es obligatorio.';
    // If too short
    if (validity.tooShort) return 'Por favor ingresa un texto de mínimo ' + field.getAttribute('minLength') + ' caracteres o más.';
    // If too long
    if (validity.tooLong) return 'Please shorten this text to no more than ' + field.getAttribute('maxLength') + ' characters. You are currently using ' + field.value.length + ' characters.';
    // If all else fails, return a generic catchall error
    return 'El valor ingresado en este campo es inválido.';
  }

  inputDesactivado(e) {
    let errorMessage = this.buscarError(e.target);
    if( errorMessage ) {
      this.showError(e.target, errorMessage);
      this.props.validacion(e, false);
    } else {
      this.setState({
        error: ""
      });
      this.props.validacion(e, true);
    }
  }

  render() {
    return (
      <FormGroup active={this.state.active} >
        {this.props.label &&
          <Label>{this.props.label}</Label>
        }
        <textarea className={'input-'+this.props.name} type={this.props.type} minLength={this.props.minLength} maxLength={this.props.maxLength} required={this.props.required} name={this.props.name} onFocus={this.inputActivo} onBlur={this.inputDesactivado} />
        {this.state.error.length > 0 &&
          <div className="error-message">
            { this.state.error }
          </div>
        }
      </FormGroup>
    )
  }
}

export class SelectGroup extends Component {
  render() {
    return (
      <FormGroup active={true} >
        <Label>{this.props.label}</Label>
        <select disabled={this.props.disabled} className={'input-'+this.props.name} required={this.props.required} defaultValue={this.props.value} name={this.props.name}>
          {this.props.children}
        </select>
        <i className="material-icons">&#xE313;</i>
      </FormGroup>
    )
  }
}

export class ButtonForm extends Component {
  render() {
    return (
      <button disabled={this.props.disabled} type="submit" className={this.props.className}>{this.props.title} <i className="material-icons">{this.props.icon}</i></button>
    )
  }
}

const Form = styled.form`
  span {
    color: #8D9398;
    font-size: 1.6em;
    margin-left: 5px;
    a {
      color: #FF0033;
    }
  }
`;

export class Formulario extends Component {
  sendForm(e) {
    e.preventDefault();
    //hasErrors('Hola');
    //let fields = e.target.elements;
    //console.log(fields);
    //let error;
    // let hasErrors;
    // for (var i = 0; i < fields.length; i++) {
    //   error = hasErrors(fields[i]);
    //   if (error) {
    //     this.props.children.forEach( child => {
    //       if ( fields[i].name === child.props.name && typeof child.type === 'function' ) {
    //         //console.log(child);
    //         //child.type.prototype.showError(fields[i], error);
    //       }
    //     })
    //     // React.Children.forEach(this.props.children, (child) => {
    //     //   if( fields[i].name === child.props.name && typeof child.type === 'function' ) {
    //     //     //child.type.prueba();
    //     //
    //     //     //mostrarError(child, error);
    //     //   }
    //     // });
    //     //console.log(fields[i]);
    //     // if (!hasErrors) {
    //     //   hasErrors = fields[i];
    //     // }
    //   }
    // }


    //console.log(this.props.children[0].props.error);

    // this.props.children.forEach( child => {
    //   for (var i = 0; i < fields.length; i++) {
    //     if( fields[i].name === child.props.name && typeof child.type === 'function' ) {
    //       //console.log(child);
    //       error = hasErrors(fields[i]);
    //       if(error) {
    //         //child.type.prototype.showError(fields[i], error);
    //
    //         console.log(child);
    //         //console.log(child);
    //       }
    //       // if (error) {
    //       //   console.log(fields[i]);
    //       // }
    //     }
    //   }
    // })

    // if (hasErrors) {
    //     e.preventDefault();
    //     hasErrors.focus();
    // }
  }

  render() {
    const childrenWrapper = React.Children.map(this.props.children, child => {
      return (
        <div className="column">
          {child}
        </div>
      );
    });
    return (
      <Form method={this.props.method} noValidate autoComplete={this.props.autoComplete} ref="refForm" onSubmit={this.props.onSubmit} className={this.props.className} >
        <div className="row">
          <div className={this.props.grid}>
            {childrenWrapper}
          </div>
        </div>
      </Form>
    )
  }
}
