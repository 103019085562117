import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AboutPage extends Component {
  componentDidMount() {
    document.title = "Acerca de XL+ | excellinked";
  }
  render() {
    return (
      <div onLoad={this.props.loadPage} className="margin-footer">
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1">
                <h1 className="title">
                  Acerca de <span>XL+ (excellinked+)</span>
                </h1>
                <div className="text-content">
                  <p>
                    Somos un equipo de profesionales con más de 10 años de
                    especialización en el manejo de proyectos de Webs y Apps
                    Móviles.
                  </p>
                  <p>
                    Nuestra experiencia nos permite manejar tiempos fluídos y
                    controlados, sin sorpresas al final de los proyectos.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a href="img/about.jpg" className="progressive replace">
                    <img
                      src="img/aboutblur.jpg"
                      className="preview"
                      alt="about"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Reconocimientos+
              </h2>
            </div>
            <div className="row">
              <div className="grid grid-lg-2 grid-md-1 col-offset-md-1 col-md-10">
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="group-card no-border"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M12 13.7C7.5 13.7 3.9 10.1 3.9 5.6L3.9 1.3C3.9 0.9 4.2 0.6 4.6 0.6L19.4 0.6C19.8 0.6 20.1 0.9 20.1 1.3L20.1 5.6C20.1 10.1 16.5 13.7 12 13.7ZM5.3 2L5.3 5.6C5.3 9.3 8.3 12.3 12 12.3 15.7 12.3 18.7 9.3 18.7 5.6L18.7 2 5.3 2Z" />
                            <path d="M11.9 19C11.5 19 11.2 18.7 11.2 18.3L11.2 16.4C11.2 16 11.5 15.7 11.9 15.7 12.3 15.7 12.6 16 12.6 16.4L12.6 18.3C12.6 18.7 12.3 19 11.9 19Z" />
                            <path d="M15.5 19.4L8.4 19.4C8 19.4 7.7 19.1 7.7 18.7 7.7 18.3 8 18 8.4 18L15.5 18C15.9 18 16.2 18.3 16.2 18.7 16.2 19.1 15.9 19.4 15.5 19.4Z" />
                            <path d="M20.7 9.7L18.9 9.7C18.5 9.7 18.2 9.4 18.2 9 18.2 8.6 18.5 8.3 18.9 8.3L20.7 8.3C21.7 8.3 22.6 7.4 22.6 6.4L22.6 2 19.4 2C19 2 18.7 1.7 18.7 1.3 18.7 0.9 19 0.6 19.4 0.6L23.3 0.6C23.7 0.6 24 0.9 24 1.3L24 6.4C24 8.2 22.5 9.7 20.7 9.7Z" />
                            <path d="M5.1 9.7L3.3 9.7C1.5 9.7 0 8.2 0 6.4L0 1.3C0 0.9 0.3 0.6 0.7 0.6L4.6 0.6C5 0.6 5.3 0.9 5.3 1.3 5.3 1.7 5 2 4.6 2L1.4 2 1.4 6.4C1.4 7.4 2.3 8.3 3.3 8.3L5.1 8.3C5.5 8.3 5.8 8.6 5.8 9 5.8 9.4 5.5 9.7 5.1 9.7Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Empresa Peruana del Año</b>
                    </p>
                    <p>
                      Categoría Desarrollo Web <br />
                      2014
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    className="group-card no-border"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="22"
                        viewBox="0 0 24 22"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M14.3 14.4L13.5 14.4C11 14.4 9 12.4 9 9.9L9 5.1C9 2.6 11 0.6 13.5 0.6L14.3 0.6C16.8 0.6 18.8 2.6 18.8 5.1L18.8 9.9C18.8 12.4 16.8 14.4 14.3 14.4ZM13.5 2C11.8 2 10.4 3.4 10.4 5.1L10.4 9.9C10.4 11.6 11.8 13 13.5 13L14.3 13C16 13 17.4 11.6 17.4 9.9L17.4 5.1C17.4 3.4 16 2 14.3 2L13.5 2Z" />
                            <path d="M23.3 21.4L4.5 21.4C4.2 21.4 3.8 21.1 3.8 20.7L3.8 19C3.8 18 5.1 17.1 8.1 16 9.5 15.6 10.8 15.3 11.5 15.1L11.5 13.8C11.5 13.4 11.8 13.1 12.2 13.1 12.6 13.1 12.9 13.4 12.9 13.8L12.9 15.6C12.9 16 12.7 16.3 12.4 16.3 8.8 17.1 5.5 18.4 5.3 19L5.3 20 23.3 20C23.7 20 24 20.3 24 20.7 24 21.1 23.7 21.4 23.3 21.4Z" />
                            <path d="M23.3 21.4C22.9 21.4 22.6 21.1 22.6 20.7L22.6 19C22.3 18.4 19 17.1 15.5 16.3 15.1 16.3 14.9 15.9 14.9 15.5 15 15.1 15.4 14.9 15.8 15 15.9 15 17.8 15.4 19.7 16 22.8 17.1 24 18 24 19.1L24 20.7C24 21.1 23.7 21.4 23.3 21.4Z" />
                            <path d="M8.4 14L7.8 14C5.8 14 4.1 12.3 4.1 10.3L4.1 6.4C4.1 4.4 5.8 2.8 7.8 2.8L8.4 2.8C8.8 2.8 9.1 3.1 9.1 3.4 9.1 3.8 8.8 4 8.4 4L7.8 4C6.4 4 5.4 5.1 5.4 6.4L5.4 10.3C5.4 11.7 6.4 12.7 7.8 12.7L8.4 12.7C8.8 12.7 9.1 13 9.1 13.3 9.1 13.7 8.8 14 8.4 14Z" />
                            <path d="M0.6 21.4C0.3 21.4 0 21.1 0 20.7L0 17.6C0 16.2 3.2 15.1 6.1 14.4L6.1 13.4C6.1 13.1 6.4 12.8 6.7 12.8 7.1 12.8 7.3 13.1 7.3 13.4L7.3 14.9C7.3 15.2 7.1 15.4 6.9 15.5 4.1 16.1 1.5 17.1 1.2 17.6L1.2 20.7C1.2 21.1 0.9 21.4 0.6 21.4Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Miembro de IBM</b>
                    </p>
                    <p>
                      Global Entrepreneur Program <br />
                      2016
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Clientes
              </h2>
            </div>
            <div className="row">
              <ul className="grid grid-xs-2 grid-md-3 grid-xl-4 logos-clientes">
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/nestle.png"
                      alt="cliente"
                      srcSet="img/logos/nestle@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/tottus.png"
                      alt="cliente"
                      srcSet="img/logos/tottus@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/ice.png"
                      alt="cliente"
                      srcSet="img/logos/ice@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/rambla.png"
                      alt="cliente"
                      srcSet="img/logos/rambla@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/asus.png"
                      alt="cliente"
                      srcSet="img/logos/asus@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/se.png"
                      alt="cliente"
                      srcSet="img/logos/se@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/pacasmayo.png"
                      alt="cliente"
                      srcSet="img/logos/pacasmayo@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/rimac.png"
                      alt="cliente"
                      srcSet="img/logos/rimac@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/upc.png"
                      alt="cliente"
                      srcSet="img/logos/upc@2x.png 2x"
                    />
                  </figure>
                </li>
                <li className="column">
                  <figure data-aos="fade-up" className="center">
                    <img
                      src="img/logos/walon.png"
                      alt="cliente"
                      srcSet="img/logos/walon@2x.png 2x"
                    />
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <hr />
                <div className="group-faq">
                  <div data-aos="fade-up" className="group-faq-title">
                    <p>
                      <b>Preguntas Frecuentes</b>
                    </p>
                    <p>Sobre excellinked</p>
                  </div>
                  <div className="group-faq-content grid grid-md-1">
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>¿Quién forma el equipo de XL+?</b>
                      </p>
                      <p>
                        Excellinked está formado por un equipo de profesionales
                        con más de 10 años de experiencia en el mercado global
                        informático, ofreciendo servicios de alta
                        especialización.
                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>¿Cuál es la principal ventaja de XL+?</b>
                      </p>
                      <p>
                        Sabemos lo importante que es sentir y recibir un
                        respaldo sólido por personas que entienden lo que usted
                        solicita y cuáles son sus necesidades.
                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>¿Por qué contratar a XL+?</b>
                      </p>
                      <p>
                        Porque tenemos el conocimiento y experiencia ya
                        desarrollados, el cual nos permite dedicarle más tiempo
                        a ponerle las cosas simples y ofrecerle servicios más
                        sencillos para su empresa.
                      </p>
                    </div>
                    <div data-aos="fade-up" className="column">
                      <p>
                        <b>
                          ¿Puedo conocer más sobre las políticas generales de
                          sus servicios?
                        </b>
                      </p>
                      <p>
                        Si. Toda esa información podrás encontrarla haciendo
                        click <Link to="/legales">aquí</Link> para que estés al
                        tanto de las novedades y mejoras en las políticas de
                        nuestros productos y servicios.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
