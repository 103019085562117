import React from "react";
import { Link } from "react-router-dom";

import logoxl from "./excellinkedplus.svg";

const Footer = () => (
  <footer id="footer-anchor" className="footer-section">
    <div className="container">
      <div className="row flex flex-wrap">
        <div className="about-footer col-xs-10 col-offset-xs-1 col-offset-lg-0 col-lg-4 flex-order-5">
          <figure>
            <Link className="logotipo" to="/">
              <img src={logoxl} alt="excellinked" />
            </Link>
          </figure>
          <ul>
            <li>
              © 2012 - 2021 <b>excellinked & XL Plus</b> <br />
              Todos los derechos reservados.
            </li>
          </ul>
          <ul className="pay-cards">
            <li>
              <img
                src={process.env.PUBLIC_URL + "/img/card-visa.svg"}
                alt="Visa"
              />
            </li>
            <li>
              <img
                src={process.env.PUBLIC_URL + "/img/card-mastercard.svg"}
                alt="MasterCard"
              />
            </li>
            <li>
              <img
                src={process.env.PUBLIC_URL + "/img/card-dinersclub.svg"}
                alt="Dinners Club"
              />
            </li>
            <li>
              <img
                src={process.env.PUBLIC_URL + "/img/card-american.svg"}
                alt="American Express"
              />
            </li>
          </ul>
        </div>
        <div className="col-lg-0 col-xs-12 margin-bottom-50 flex-order-4"></div>
        <div className="col-xs-5 col-offset-xs-1 col-sm-3 col-offset-lg-0 col-lg-2">
          <p>
            <b>Páginas</b>
          </p>
          <ul>
            <li>
              <Link to="/nosotros">Nosotros</Link>
            </li>
            <li>
              <Link to="/servicios">Servicios</Link>
            </li>
            <li>
              <Link to="/proyectos">Proyectos</Link>
            </li>
            <li>
              <Link to="/servidores">Servidores</Link>
            </li>
          </ul>
        </div>
        <div className="col-xs-5 col-sm-3 col-lg-2">
          <p>
            <b>Otros Enlaces</b>
          </p>
          <ul>
            <li>
              <Link to="/legales">Legales</Link>
            </li>
            <li>
              <Link to="/bancos">Bancos</Link>
            </li>
            <li>
              <Link to="/miip">Mi IP</Link>
            </li>
            <li>
              <Link to="/xl-plus">XL+</Link>
            </li>
          </ul>
        </div>
        <div className="col-sm-0 col-xs-12 margin-bottom-50"></div>
        <div className="col-xs-10 col-offset-xs-1 col-sm-4 col-offset-sm-0 col-md-4">
          <p>
            <b>Contacto</b>
          </p>
          <ul>
            <li>
              <b>
                <Link to="/nosotros">hola@xl.com.pe</Link>
              </b>{" "}
              <br />
              Whatsapp Negocios +51 9 45677 920
              <br />
              San Borja - Lima, Perú
            </li>
          </ul>
          <div className="social-media flex align-items-center">
            <a
              className="icon-facebook flex align-items-center"
              target="_blank"
              href="https://www.facebook.com/excellinked"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="18"
                viewBox="0 0 8 18"
              >
                <g fill="#AEABAB">
                  <path d="M8 5.6L5.3 5.6 5.3 3.8C5.3 3.1 5.7 3 6 3 6.3 3 8 3 8 3L8 0 5.3 0C2.4 0 1.7 2.2 1.7 3.6L1.7 5.6 0 5.6 0 8.6 1.7 8.6C1.7 12.5 1.7 17.2 1.7 17.2L5.3 17.2C5.3 17.2 5.3 12.5 5.3 8.6L7.7 8.6 8 5.6Z" />
                </g>
              </svg>
            </a>
            <a
              className="icon-twitter flex align-items-center"
              target="_blank"
              href="https://twitter.com/excellinked"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
              >
                <g fill="#AEABAB">
                  <path d="M18 1.7C17.3 2 16.6 2.2 15.9 2.3 16.6 1.9 17.2 1.1 17.5 0.3 16.8 0.7 16 1 15.2 1.2 14.5 0.4 13.5 0 12.5 0 10.4 0 8.8 1.7 8.8 3.7 8.8 4 8.8 4.3 8.9 4.5 5.8 4.4 3.1 2.9 1.3 0.7 0.9 1.2 0.8 1.9 0.8 2.5 0.8 3.8 1.4 4.9 2.4 5.6 1.8 5.6 1.2 5.4 0.7 5.1 0.7 5.2 0.7 5.2 0.7 5.2 0.7 7 2 8.5 3.7 8.8 3.4 8.9 3 8.9 2.7 8.9 2.5 8.9 2.2 8.9 2 8.9 2.5 10.3 3.9 11.4 5.5 11.4 4.2 12.4 2.6 13 0.9 13 0.6 13 0.3 13 0 13 1.6 14 3.6 14.6 5.7 14.6 12.5 14.6 16.2 9 16.2 4.1 16.2 4 16.2 3.8 16.2 3.6 16.9 3.1 17.5 2.5 18 1.7Z" />
                </g>
              </svg>
            </a>
            <a
              className="icon-linkedin flex align-items-center"
              target="_blank"
              href="https://www.linkedin.com/company/excellinked"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="17"
                viewBox="0 0 18 17"
              >
                <g fill="#AEABAB">
                  <polygon points="0.6 5.5 4.3 5.5 4.3 16.7 0.6 16.7" />
                  <path d="M2.5 4.1C1.2 4.1 0.4 3.2 0.4 2.1 0.4 1 1.2 0.2 2.5 0.2 3.8 0.2 4.5 1 4.6 2.1 4.6 3.2 3.7 4.1 2.5 4.1Z" />
                  <path d="M17.7 16.7L14 16.7 14 10.8C14 9.3 13.4 8.3 12.1 8.3 11 8.3 10.4 9 10.2 9.6 10.1 9.9 10.1 10.2 10.1 10.5L10.1 16.7 6.3 16.7C6.3 16.7 6.4 6.6 6.3 5.5L10.1 5.5 10.1 7.2C10.5 6.4 11.5 5.4 13.4 5.4 15.8 5.4 17.7 7 17.7 10.4L17.7 16.7Z" />
                </g>
              </svg>
            </a>
            {/* <Link className="icon-whatsapp flex align-items-center" to="/whatsapp">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19"><g fill="#AEABAB"><path d="M15.4 2.8C13.7 1.1 11.4 0.2 9 0.2 4.1 0.2 0.1 4.2 0.1 9.1 0.1 10.7 0.5 12.2 1.3 13.6L0 18.2 4.8 17C6.1 17.7 7.5 18 9 18L9 18C9 18 9 18 9 18 14 18 18 14 18 9.1 18 6.7 17 4.5 15.4 2.8ZM9 16.5L9 16.5C7.7 16.5 6.4 16.2 5.3 15.5L5 15.3 2.2 16.1 2.9 13.3 2.7 13.1C2 11.9 1.6 10.5 1.6 9.1 1.6 5 4.9 1.7 9 1.7 11 1.7 12.9 2.4 14.3 3.9 15.7 5.3 16.5 7.1 16.5 9.1 16.5 13.2 13.1 16.5 9 16.5Z"/><path d="M13.1 11C12.9 10.9 11.8 10.3 11.6 10.2 11.4 10.2 11.2 10.1 11.1 10.4 10.9 10.6 10.5 11.1 10.4 11.2 10.2 11.4 10.1 11.4 9.9 11.3 9.7 11.2 8.9 10.9 8.1 10.2 7.4 9.6 7 8.9 6.9 8.6 6.7 8.4 6.8 8.3 7 8.2 7.1 8.1 7.2 7.9 7.3 7.8 7.4 7.7 7.4 7.6 7.5 7.4 7.6 7.3 7.5 7.1 7.5 7 7.4 6.9 7 5.8 6.8 5.4 6.6 4.9 6.4 5 6.3 5 6.2 5 6 5 5.9 5 5.7 5 5.5 5 5.3 5.3 5.1 5.5 4.5 6 4.5 7.1 4.5 8.2 5.3 9.3 5.4 9.4 5.5 9.6 7 11.8 9.2 12.8 9.8 13 10.2 13.2 10.5 13.3 11 13.4 11.5 13.4 11.9 13.4 12.3 13.3 13.2 12.8 13.4 12.3 13.6 11.8 13.6 11.3 13.5 11.2 13.5 11.1 13.3 11.1 13.1 11Z"/></g></svg>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
