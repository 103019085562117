import React, { Component } from 'react';
import ContactSeccion from './../components/contact';

export default class MixedReality extends Component {
  // pageActive pageEnter
  componentDidMount() {
    document.title = 'Realidad Mixta XL | excellinked';
  }

  render() {
    return (
      <div onLoad={this.props.loadPage}>
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1">
                <h1 className="title">
                  Realidad+ <span>mixta</span>
                </h1>
                <div className="text-content">
                  <p>
                    Es la evolución de la realidad aumentada, porque permite
                    desarrollar aplicaciones para dispositivos móviles, con una
                    experiencia inmersiva y divertida, combinando lo que será
                    virtual y real para el usuario.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a
                    href="img/realidadmixta.jpg"
                    className="progressive replace"
                  >
                    <img
                      src="img/realidadmixtablur.jpg"
                      className="preview"
                      alt="realidad mixta"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Ventajas
              </h2>
            </div>
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <div className="group-faq">
                  <div className="row">
                    <div className="group-faq-content grid grid-md-1">
                      <div data-aos="fade-up" className="column">
                        <p>
                          - Se eliminan los altos costos de recursos y hardware
                          al tener que crear un escenario 100% virtual, donde lo
                          único real es el usuario.
                        </p>
                        <p>
                          - La experiencia del usuario será más cómoda y menos
                          cansada para sus ojos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSeccion />
      </div>
    );
  }
}
