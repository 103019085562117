import React, { Component } from 'react';

import { Formulario, InputGroup, SelectGroup, ButtonForm } from './../Form';
import paises from './../paises.json';

const HostingPlan = props => (
  <div className="container">
    <div className="row">
      <div className="grid col-md-10 grid-md-2 col-offset-md-1 col-xl-12 grid-xl-3 col-offset-xl-0 align-items-stretch">
        <div className="column">
          <div data-aos="fade-up" className="plan-group">
            <p>
              <b>XL+ Startup</b>
            </p>
            <p className="plan-price">
              <sup>{props.moneda === 'USD' ? `$` : 's/.'}</sup>
              {props.moneda === 'USD'
                ? '34'
                : `${(props.cambio * 34).toFixed(2)}`}
            </p>
            <p>
              <span>anual</span>
            </p>
            <ul>
              <li>15 GB Tráfico mensual</li>
              <li>2000 Mb Espacio</li>
              <li>Aliases ilimitado</li>
              <li>5 cuentas de email</li>
              <li>10 cuentas FTP</li>
              <li>2 Subdominios</li>
              <li>Soporte Español e Inglés</li>
              <li>Backup incluido</li>
            </ul>
            <button
              value="Hosting - XL Startup"
              id="btnCulqi"
              onClick={props.mostrar}
              className="btn btn-accent"
            >
              Comprar
            </button>
          </div>
        </div>
        <div className="column">
          <div data-aos="fade-up" data-aos-delay="100" className="plan-group">
            <p>
              <b>XL+ Senior</b>
            </p>
            <p className="plan-price">
              <sup>{props.moneda === 'USD' ? `$` : 's/.'}</sup>
              {props.moneda === 'USD'
                ? '56'
                : `${(props.cambio * 56).toFixed(2)}`}
            </p>
            <p>
              <span>anual</span>
            </p>
            <ul>
              <li>30 GB Tráfico mensual</li>
              <li>3500 Mb Espacio</li>
              <li>Aliases ilimitado</li>
              <li>10 cuentas de email</li>
              <li>10 cuentas FTP</li>
              <li>3 Subdominios</li>
              <li>Soporte Español e Inglés</li>
              <li>Backup incluido</li>
            </ul>
            <button
              value="Hosting - XL Senior"
              onClick={props.mostrar}
              className="btn btn-accent"
            >
              Comprar
            </button>
          </div>
        </div>
        <div className="column">
          <div data-aos="fade-up" data-aos-delay="200" className="plan-group">
            <p>
              <b>XL+ Pro</b>
            </p>
            <p className="plan-price">
              <sup>{props.moneda === 'USD' ? `$` : 's/.'}</sup>
              {props.moneda === 'USD'
                ? '75'
                : `${(props.cambio * 75).toFixed(2)}`}
            </p>
            <p>
              <span>anual</span>
            </p>
            <ul>
              <li>45 GB Tráfico mensual</li>
              <li>5000 Mb Espacio</li>
              <li>Aliases ilimitado</li>
              <li>20 cuentas de email</li>
              <li>10 cuentas FTP</li>
              <li>4 Subdominios</li>
              <li>Soporte Español e Inglés</li>
              <li>Backup incluido</li>
            </ul>
            <button
              value="Hosting - XL Pro"
              onClick={props.mostrar}
              className="btn btn-accent"
            >
              Comprar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const CloudPlan = props => (
  <div className="container">
    <div className="row">
      <div className="grid col-md-10 grid-md-2 col-offset-md-1 col-xl-12 grid-xl-3 col-offset-xl-0">
        <div className="column">
          <div data-aos="fade-up" className="plan-group">
            <p>
              <b>CXL+ Startup</b>
            </p>
            <p className="plan-price">
              <sup>{props.moneda === 'USD' ? `$` : 's/.'}</sup>
              {props.moneda === 'USD'
                ? '75'
                : `${(props.cambio * 75).toFixed(2)}`}
            </p>
            <p>
              <span>mensual</span>
            </p>
            <ul>
              <li>2 TB Tráfico mensual</li>
              <li>2 GB de RAM dedicada</li>
              <li>Aliases ilimitado</li>
              <li>Email ilimitado</li>
              <li>FTP ilimitado</li>
              <li>1 IP dedicada</li>
              <li>Servidor DNS Interno</li>
              <li>Soporte Español e Inglés</li>
            </ul>
            <button
              value="Cloud - XL Startup"
              onClick={props.mostrar}
              className="btn btn-accent"
            >
              Solicitar
            </button>
          </div>
        </div>
        <div className="column">
          <div data-aos="fade-up" data-aos-delay="100" className="plan-group">
            <p>
              <b>CXL+ Senior</b>
            </p>
            <p className="plan-price">
              <sup>{props.moneda === 'USD' ? `$` : 's/.'}</sup>
              {props.moneda === 'USD'
                ? '88'
                : `${(props.cambio * 88).toFixed(2)}`}
            </p>
            <p>
              <span>mensual</span>
            </p>
            <ul>
              <li>4 TB Tráfico mensual</li>
              <li>6 GB de RAM dedicada</li>
              <li>Aliases ilimitado</li>
              <li>Email ilimitado</li>
              <li>FTP ilimitado</li>
              <li>2 IP dedicada</li>
              <li>Servidor DNS Dedicado</li>
              <li>Soporte Español e Inglés</li>
            </ul>
            <button
              value="Cloud - XL Senior"
              onClick={props.mostrar}
              className="btn btn-accent"
            >
              Solicitar
            </button>
          </div>
        </div>
        <div className="column">
          <div data-aos="fade-up" data-aos-delay="200" className="plan-group">
            <p>
              <b>CXL+ Pro</b>
            </p>
            <p className="plan-price">
              <sup>{props.moneda === 'USD' ? `$` : 's/.'}</sup>
              {props.moneda === 'USD'
                ? '100'
                : `${(props.cambio * 100).toFixed(2)}`}
            </p>
            <p>
              <span>mensual</span>
            </p>
            <ul>
              <li>4 TB Tráfico mensual</li>
              <li>6 GB de RAM dedicada</li>
              <li>Aliases ilimitado</li>
              <li>Email ilimitado</li>
              <li>FTP ilimitado</li>
              <li>3 IP dedicada</li>
              <li>Servidor DNS Dedicado</li>
              <li>Soporte Español e Inglés</li>
            </ul>
            <button
              value="Cloud - XL Pro"
              onClick={props.mostrar}
              className="btn btn-accent"
            >
              Solicitar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default class ServidoresPage extends Component {
  constructor() {
    super();
    //this.inputActivo = this.inputActivo.bind(this);
    //this.inputDesactivado = this.inputDesactivado.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validation = this.validation.bind(this);
    this.showForm = this.showForm.bind(this);
    this.state = {
      hostingShow: true,
      formShow: false,
      value: 'Hosting - XL Startup',
      moneda: 'USD',
      tipoCambio: 3.27,
      formValid: {
        nombre: false,
        apellido: false,
        empresa: true,
        documento: false,
        direccion: false,
        distrito: false,
        ciudad: false,
        pais: true,
        telefono: false,
        email: false,
        plan: true,
        dominio: true,
        terminos: false,
      },
      buttonInvalid: true,
      paises: [],
    };
  }

  validation(field, valid) {
    let nameInput = field.target.name;
    let estado = this.state.formValid;
    estado[nameInput] = valid;
    let validar = Object.values(estado);
    let listo = 0;
    validar.forEach(valor => {
      if (valor) {
        return listo++;
      }
    });
    if (listo >= validar.length) {
      this.setState({
        buttonInvalid: false,
      });
    } else {
      this.setState({
        buttonInvalid: true,
      });
    }
    this.setState({
      formValid: estado,
    });
  }

  toggleBox = e => {
    if (e.currentTarget.value === 'hosting') {
      this.refs.radioPlan.classList.remove('check-der');
      this.refs.radioPlan.classList.add('check-izq');
      this.setState({
        hostingShow: true,
      });
    } else {
      this.refs.radioPlan.classList.remove('check-izq');
      this.refs.radioPlan.classList.add('check-der');
      this.setState({
        hostingShow: false,
      });
    }
  };

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  hideForm() {
    this.setState({
      formShow: false,
      formValid: {
        nombre: false,
        apellido: false,
        empresa: true,
        documento: false,
        direccion: false,
        distrito: false,
        ciudad: false,
        pais: true,
        telefono: false,
        email: false,
        plan: true,
        dominio: true,
        terminos: false,
      },
    });
  }

  showForm(e) {
    this.setState({
      value: e.target.value,
      formShow: true,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.client(this.state.moneda, this.state.tipoCambio);
  }

  cambiarMoneda(e) {
    this.setState({
      moneda: e.target.value,
    });
  }

  componentDidMount() {
    document.title = 'Servidores XL | excellinked';
    this.setState({
      paises: paises,
    });
    this.props.loadPage();
  }

  componentWillMount() {
    // Antes del render
    fetch('cambio.txt')
      .then(res => {
        return res.text();
      })
      .then(data => {
        this.setState({
          tipoCambio: Number(data),
        });
      });
  }

  render() {
    let moneda = this.state.moneda;
    return (
      <div className="margin-footer">
        <section className="page-int-header page-int-noimg">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-offset-md-1">
                <h1 className="title">
                  Planes en <span>servidores</span>
                </h1>
                <div className="text-content">
                  <p>
                    Ofrecemos servidores Intel de alto rendimiento y robusta
                    seguridad, que minimizan las incidencias y garantizan una
                    conexión fluída para cualquiera de tus proyectos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              {/* <h2 className="subtitle">Planes</h2> */}
              <div ref="radioPlan" className="radio-plan check-izq">
                <button onClick={this.toggleBox} value="hosting">
                  Hosting
                </button>
                <button onClick={this.toggleBox} value="cloud">
                  Cloud
                </button>
              </div>
            </div>
            <div className="row">
              <div className="container">
                <div className="tipoCambio flex align-items-center">
                  <p>Tipo de cambio referencial: {this.state.tipoCambio}</p>
                  <select onChange={this.cambiarMoneda.bind(this)}>
                    <option value="USD">USD</option>
                    <option value="PEN">Soles</option>
                  </select>
                  <i className="material-icons">&#xE313;</i>
                </div>
              </div>
              {this.state.hostingShow && (
                <HostingPlan
                  mostrar={this.showForm}
                  moneda={this.state.moneda}
                  cambio={this.state.tipoCambio}
                />
              )}
              {!this.state.hostingShow && (
                <CloudPlan
                  mostrar={this.showForm}
                  moneda={this.state.moneda}
                  cambio={this.state.tipoCambio}
                />
              )}
              <div className="plan-footer col-md-10 col-offset-md-1 col-xl-12">
                <p>
                  <i className="material-icons">done</i> Los precios NO incluyen
                  IGV.
                </p>
                <p>
                  <i className="material-icons">done</i> Planes Cloud mínimo
                  contrato de 3 meses.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <div className="grid grid-md-2">
                  <div className="column">
                    <hr />
                    <div data-aos="fade-up" className="group-banco">
                      <div className="group-banco-title">
                        <p className="banco-bcp">
                          <b>BCP Banco de Crédito</b>
                        </p>
                      </div>
                      <div className="group-banco-content">
                        <div>
                          <p>
                            <b>AHORRO NEGOCIOS S/</b>
                          </p>
                          <p>19435877569066</p>
                        </div>
                        <div>
                          <p>
                            <b>CCI (S/.)</b>
                          </p>
                          <p>00219413587756906693–20</p>
                        </div>
                        <div>
                          <p>
                            <b>AHORRO NEGOCIOS US$</b>
                          </p>
                          <p>19435926322112</p>
                        </div>
                        <div>
                          <p>
                            <b>CCI (US$)</b>
                          </p>
                          <p>00219413592632211298–24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <hr />
                    <div data-aos="fade-up" className="group-banco">
                      <div className="group-banco-title">
                        <p className="banco-interbank">
                          <b>Banco de la Nación</b>
                        </p>
                      </div>
                      <div className="group-banco-content">
                        <div>
                          <p>
                            <b>CUENTA DETRACCIONES</b>
                          </p>
                          <p>00-048-149529</p>
                        </div>
                        {/* <div> */}
                        {/*   <p> */}
                        {/*     <b>Razón social: XL PLUS</b> */}
                        {/*   </p> */}
                        {/*   <p>RUC: 20607112445</p> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <hr />
                <div className="group-faq">
                  <div data-aos="fade-up" className="group-faq-title">
                    <p>
                      <b>Preguntas+ Frecuentes</b>
                    </p>
                    <p>Hosting y Servidores Cloud</p>
                  </div>
                  <div className="row">
                    <div className="group-faq-content grid grid-md-1">
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>¿Qué es hosting+?</b>
                        </p>
                        <p>
                          Es un servicio de “hospedaje” de páginas web. Es
                          decir, un servicio de hosting ofrece un lugar para
                          hospedar a una web con toda su información además de
                          dar, de manera adicional, servicio de correo
                          electrónico corporativo, gestión de la web, dominios y
                          mucho más.
                        </p>
                      </div>
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>¿Qué es un servidor cloud+?</b>
                        </p>
                        <p>
                          Es una plataforma virtual que ofrece espacio de
                          almacenamiento y acceso para gestionar tu información.
                          La tecnología Cloud te brinda la ventaja de no tener
                          que dedicarle un espacio en tus instalación ya que
                          ésta se encuentra “en la nube”. Mucho más comodidad y
                          seguridad para tu información.
                        </p>
                      </div>
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>¿Qué es un servidor dedicado+?</b>
                        </p>
                        <p>
                          Un servidor dedicado es un servicio en el que todos
                          los recursos del servidor se ponen a disponibilidad de
                          un solo usuario, de esta manera se convierte en un
                          servicio exclusivo y personalizado. Al no compartir
                          recursos con ningún otro usuario, obtendrás el máximo
                          rendimiento del servidor para adaptarse a tus
                          necesidades.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.formShow && (
          <div className="form-service">
            <div className="form-service-popup col-xs-10 col-offset-xs-1 col-md-8 col-offset-md-2">
              <h2 className="subtitle">Solicitud</h2>
              <Formulario
                method="post"
                onSubmit={e => this.handleSubmit(e)}
                autoComplete="off"
                className="form-pay"
                grid="grid grid-md-2"
              >
                <InputGroup
                  type="text"
                  name="nombre"
                  required
                  label="Nombre"
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                  title="Solo se aceptan letras"
                  validacion={this.validation}
                />
                <InputGroup
                  type="text"
                  name="apellido"
                  required
                  label="Apellido"
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                  title="Solo se aceptan letras"
                  validacion={this.validation}
                />
                <InputGroup
                  type="text"
                  name="empresa"
                  label="Empresa"
                  validacion={this.validation}
                />
                <InputGroup
                  type="number"
                  name="documento"
                  required
                  label="DNI, CE o RUC (solo factura)"
                  minLength="4"
                  maxLength="11"
                  pattern="[^0-9]"
                  title="Solo se aceptan números"
                  validacion={this.validation}
                />
                <InputGroup
                  type="text"
                  name="direccion"
                  required
                  label="Dirección"
                  validacion={this.validation}
                />
                <InputGroup
                  type="text"
                  name="distrito"
                  required
                  label="Distrito"
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                  title="Solo se aceptan letras"
                  validacion={this.validation}
                />
                <InputGroup
                  type="text"
                  name="ciudad"
                  required
                  label="Ciudad"
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                  title="Solo se aceptan letras"
                  validacion={this.validation}
                />
                <SelectGroup name="pais" required value="PE" label="País">
                  {this.state.paises.map(pais => (
                    <option key={pais.code} value={pais.alpha}>
                      {pais.name}
                    </option>
                  ))}
                </SelectGroup>
                <InputGroup
                  type="number"
                  name="telefono"
                  required
                  label="Teléfono"
                  minLength="7"
                  validacion={this.validation}
                />
                <InputGroup
                  type="email"
                  name="email"
                  required
                  label="Email"
                  validacion={this.validation}
                />
                <SelectGroup
                  name="plan"
                  required
                  value={this.state.value}
                  label="Plan"
                >
                  <option value="Hosting - XL Startup">
                    Hosting Startup -{' '}
                    {moneda === 'USD'
                      ? '$34'
                      : `s/. ${this.state.tipoCambio * 34}`}{' '}
                    + IGV
                  </option>
                  <option value="Hosting - XL Senior">
                    Hosting Senior -{' '}
                    {moneda === 'USD'
                      ? '$56'
                      : `s/. ${this.state.tipoCambio * 56}`}{' '}
                    + IGV
                  </option>
                  <option value="Hosting - XL Pro">
                    Hosting Pro -{' '}
                    {moneda === 'USD'
                      ? '$75'
                      : `s/. ${this.state.tipoCambio * 75}`}{' '}
                    + IGV
                  </option>
                  <option value="Hosting - XL Ultimate">
                    Hosting Ultimate -{' '}
                    {moneda === 'USD'
                      ? '$96'
                      : `s/. ${this.state.tipoCambio * 96}`}{' '}
                    + IGV
                  </option>
                </SelectGroup>
                <SelectGroup name="dominio" required value="PE" label="Dominio">
                  <option value="deseo-dominio">Deseo un dominio</option>
                  <option value="transferir-dominio">
                    Tranferir un dominio
                  </option>
                  <option value="no-necesito-dominio">
                    No necesito un dominio
                  </option>
                </SelectGroup>
                <div>
                  <input
                    name="terminos"
                    onChange={e =>
                      this.validation(e, !this.state.formValid.terminos)
                    }
                    required
                    type="checkbox"
                  />{' '}
                  <span>
                    Acepto los{' '}
                    <a href="/" target="_blank">
                      términos y condiciones
                    </a>
                  </span>
                </div>
                <ButtonForm
                  className="btn-form link"
                  title="ir pagar"
                  icon="trending_flat"
                  disabled={this.state.buttonInvalid}
                />
              </Formulario>
            </div>
            <div className="hide-form" onClick={this.hideForm.bind(this)} />
          </div>
        )}
      </div>
    );
  }
}
