import React, { Component } from 'react';

export default class LegalesPage extends Component {
  constructor() {
    super();
    this.labelActivo = this.labelActivo.bind(this);
    this.state = {
      labelShow: 1,
    };
  }

  labelActivo(e) {
    let labelValue = Number(e.currentTarget.value);
    this.setState({
      labelShow: labelValue,
    });
    document.querySelector('.label-active').classList.remove('label-active');
    e.target.classList.add('label-active');
  }

  componentDidMount() {
    document.title = 'Legales XL | excellinked';
  }

  render() {
    return (
      <div onLoad={this.props.loadPage} className="margin-footer">
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-offset-md-1">
                <h1 className="title">
                  Nuestras <span>políticas+</span>
                </h1>
                <div className="text-content">
                  <p>
                    Ofrecemos servidores Intel de alto rendimiento y seguridad,
                    que minimizan los tiempos muertos y garantizan una
                    navegación más ligera en su página Web, e-Commerce, Apps y
                    cuentas de email.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  title="img/legales.jpg"
                  className="img-content flex align-items-center"
                >
                  <a href="img/legales.jpg" className="progressive replace">
                    <img
                      src="img/legalesblur.jpg"
                      className="preview"
                      alt="legales"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xs-10 col-offset-xs-1">
                <div className="row">
                  <div className="grid grid-md-3 link-politicas">
                    <div className="column">
                      <button
                        value="1"
                        className="label-active"
                        onClick={this.labelActivo}
                      >
                        Términos de los servicios
                      </button>
                    </div>
                    <div className="column">
                      <button value="2" onClick={this.labelActivo}>
                        Acuerdo de acceso al contenido y utilización de los
                        servicios web
                      </button>
                    </div>
                    <div className="column">
                      <button value="3" onClick={this.labelActivo}>
                        Condiciones generales de contratación
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.labelShow === 1 && (
                <div className="col-md-10 col-offset-md-1">
                  <div data-aos="fade-up" className="content">
                    <p>
                      El presente aviso legal (en adelante, "Aviso Legal")
                      regula el portal web así como todo el contenido y
                      servicios incluidos en los mismos, de propiedad, gestión
                      y/o administración de Excellinked.
                    </p>
                    <p>
                      La utilización del portal atribuye al visitante la
                      condición de usuario e implica la aceptación plena y sin
                      reservas de todas y cada una de las disposiciones
                      incluidas en este Aviso Legal, en la versión publicada por
                      Excellinked en el momento mismo en que el usuario acceda
                      al portal web, o a cualquier contenido o servicio incluido
                      en el mismo. En consecuencia, el usuario debe leer
                      atentamente el presente Aviso Legal en cada una de las
                      ocasiones en que se proponga utilizar el portal web, o
                      acceder a cualquier contenido y/o servicio incluido en los
                      mismos, ya que el Aviso Legal o cualquiera disposición o
                      sección del mismo pueden sufrir modificaciones cada cierto
                      tiempo.
                    </p>
                    <p>
                      La utilización de cierto contenido y/o servicios ofrecidos
                      a los usuarios a través del portal web se encuentra
                      sometida a condiciones particulares propias las cuales
                      serán informadas al usuario de forma oportuna y que, según
                      los casos, pueden sustituir, complementar y/o modificar
                      los términos del presente Aviso Legal. Por lo tanto, con
                      anterioridad a la utilización de tales contenidos y/o
                      servicios, el usuario también deberá leer atentamente las
                      correspondientes condiciones particulares de cada
                      servicio.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Propiedad intelectual de los contenidos en la web</b>
                    </p>
                    <p>
                      Los contenidos, información, elementos gráficos y demás
                      obras contenidas y/o publicadas en éste sitio, los
                      servicios difundidas y/u ofrecidos a través de este
                      portal, son de titularidad exclusiva de Excellinked, los
                      mismos que ostentan los derechos de explotación
                      correspondiente.
                    </p>
                    <p>
                      Respecto de los contenidos de titularidad de Excellinked ,
                      se prohíbe cualquier reproducción, fijación (salvo la
                      reproducción y/o fijación realizada de forma temporal en
                      la memoria RAM del ordenador del usuario), comunicación
                      pública, transformación, distribución, alquiler, préstamo
                      público, publicación, importación de copias o ejemplares,
                      redistribución, radiodifusión, transmisión o
                      retransmisión, por cualquier forma o medio analógico o
                      digital, alámbrico o inalámbrico, conocido o por
                      conocerse; sin el previo consentimiento de Excellinked ,
                      salvo que se indique lo contrario para un contenido en
                      particular o que, por la naturaleza de la tecnología por
                      la cual se transmite tal contenido, alguna de las acciones
                      antes indicadas sea necesaria de realizar pero con el
                      único objetivo de que el usuario pueda acceder a tal
                      contenido.
                    </p>
                    <p>
                      En cualquier caso de utilización de cualquier contenido de
                      titularidad Excellinked, siempre se deberá indicar como
                      fuente del contenido en cuestión a Excellinked.
                    </p>
                    <p>
                      Respecto de los contenidos, servicios, información y obras
                      de titularidad de terceros, las mismas se rigen por las
                      licencias particulares que cada autor o titular estipula
                      para sus obras; pero en principio, el usuario no podrá
                      reproducir, fijar, comunicar públicamente, transformar,
                      distribuir, alquilar, prestar públicamente, publicar,
                      importar copias o ejemplares, redistribuir, realizar
                      radiodifusión, transmitir o retransmitir, por cualquier
                      forma o medio analógico o digital, alámbrico o
                      inalámbrico, conocido o por conocerse; sin el previo y
                      expreso consentimiento de sus respectivos autores o
                      titulares, y siempre de acuerdo al principio de usos
                      honestos.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>
                        Ley aplicable y jurisdicción para la resolución de
                        controversias
                      </b>
                    </p>
                    <p>
                      En el caso de que cualquier disposición o sección del
                      presente Aviso Legal fuera(n) considerado(s) nulo(s) o
                      inaplicable(s) por cualquier juzgado, tribunal u órgano
                      administrativo competente, dicha nulidad o inaplicación no
                      afectará a las otras disposiciones del presente Aviso
                      Legal.
                    </p>
                    <p>
                      La relación entre Excellinked y cualquier usuario se rige,
                      en principio, por los términos del presente Aviso Legal.
                      En todo lo no previsto en el presente documento, sea
                      aplicará supletoriamente lo dispuesto en la legislación
                      peruana vigente.
                    </p>
                    <p>
                      Excellinked y el usuario, con renuncia expresa a cualquier
                      otro fuero, se someten al de los Juzgados y Tribunales del
                      Distrito Judicial de Lima, Perú.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>
                        Sobre la calidad e idoneidad de los servicios y
                        productos
                      </b>
                    </p>
                    <p>
                      Excellinked hará su mejor esfuerzo para proveer una
                      disponibilidad de conexión de sus servidores por acceso
                      http superior al 99.9%. Sin embargo, Excellinked no es
                      responsable por ningún daño sufrido por el usuario como
                      resultado del mantenimiento u otros tiempos de inactividad
                      del servidor de Excellinked y el portal web. El usuario
                      exime de responsabilidad a Excellinked por cualquier daño
                      sufrido, incluido el lucro cesante y daño emergente que
                      pueda ocasionarse por tales hechos.
                    </p>
                    <p>
                      Pueden existir períodos de mantenimiento del servidor de
                      Excellinked que puedan afectar el acceso al portal y/o el
                      uso de los servicios y/o funcionalidades del sitio web.
                      Excellinked no será responsable por ningún daño o
                      perjuicio sufrido por el usuario debido a causas
                      imprevistas o causa fuera del control de Excellinked ,
                      tales como hechos fortuitos o de fuerza mayor, incluyendo
                      actos de la naturaleza, actos de autoridades civiles o
                      militares, cortes del servicio de energía eléctrica o de
                      las telecomunicaciones, entre otros.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Aviso de Cookies</b>
                    </p>
                    <p>
                      Excellinked podrá utilizar "Cookies" u otra tecnología
                      similar, que son pequeños pedazos de texto que solicitan
                      ser colocados por los portales en el disco duro de la
                      computadora del usuario con el objeto de informar a
                      Excellinked cada vez que un antiguo usuario ha ingresado
                      nuevamente a los portales y así facilitar la navegación y
                      uso de los mismos. El usuario tiene la posibilidad de
                      configurar su programa de navegador de Internet para ser
                      avisado en su pantalla de la recepción de "Cookies" e
                      impedir la instalación de los mismos en su disco duro. Tal
                      operación de bloqueo no impide navegar en el sitio web,
                      pero su acceso podría ser un poco más lento de lo normal.
                      La información contenida en los "Cookies" tendrá carácter
                      confidencial y aplicará las mismas políticas que las
                      descritas para el caso de los Datos Personales.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Política de protección de datos personales PdP</b>
                    </p>
                    <p>
                      De conformidad con la Ley PdP, desde el momento de su
                      ingreso al portal web y/o utilización de los servicios, el
                      usuario otorga un consentimiento expreso a Excellinked
                      para el tratamiento de sus datos personales facilitados.
                      Asimismo, el usuario consiente expresamente que
                      Excellinked pueda ceder dichos datos personales aun a
                      terceros.
                    </p>
                    <p>
                      La información personal del usuario se procesa y almacena
                      en servidores o medios magnéticos que mantienen altos
                      estándares de seguridad y protección tanto física como
                      tecnológica. Una vez registrados los datos del usuario,
                      Excellinked no venderá, alquilará o compartirá la
                      información personal, excepto en las formas establecidas
                      en la presente sección, y en los límites permitidos por
                      las normas peruanas.
                    </p>
                    <p>
                      Puede suceder que, en virtud de órdenes judiciales o de
                      regulaciones legales, Excellinked se vea obligado a
                      revelar información personal a las autoridades o terceras
                      partes bajo ciertas circunstancias, o bien en casos que
                      terceras partes puedan interceptar o acceder a cierta
                      información o transmisiones de datos, en cuyo caso
                      Excellinked no responderá por la información que sea
                      revelada. El usuario autoriza a Excellinked a conservar
                      sus datos una vez finalizada la relación con este, para el
                      cumplimiento de las obligaciones legales pertinentes, y
                      para que pueda recibir información publicitaria y ofertas
                      comerciales.
                    </p>
                    <p>
                      En cuanto entre en vigencia las disposiciones pertinentes
                      de la Ley N° 29733, al usuario le asiste de ejercer los
                      derechos de acceso, rectificación, oposición y cancelación
                      de los datos personales, los cuales podrá ejercer mediante
                      la opción de contacto que Excellinked pondrá a su
                      disposición.
                    </p>
                    <p>
                      En el caso que el usuario realice la comunicación de sus
                      datos de carácter personal en espacios de participación
                      externos a este portal web, Excellinked no será
                      responsable de la recolección u otro tratamiento que
                      realicen terceros. Por ello, se recomienda al usuario no
                      publicar sus datos personales en tales espacios públicos.
                    </p>
                    <p>
                      Excellinked ha adoptado los niveles de seguridad de
                      protección de los Datos Personales legalmente requeridos.
                      No obstante, el usuario debe ser consciente de que dichas
                      medidas en Internet no son inexpugnables.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Sobre los ficheros de datos</b>
                    </p>
                    <p>
                      Los datos personales serán incorporados al fichero de
                      usuarios de titularidad de Excellinked.
                    </p>
                    <p>
                      El usuario presta su consentimiento para que se puedan
                      transferir sus datos en los términos y condiciones
                      anteriormente indicados, a otras entidades con destino a
                      un país extranjero. Los datos se mantendrán en el fichero
                      mientras se consideren útiles con el fin que Excellinked
                      pueda prestar los servicios; enviar al usuario información
                      y publicidad sobre las ofertas, promociones y
                      recomendaciones de cualquier tipo del propio Excellinked o
                      de terceros que considere pertinente, así como para
                      realizar encuestas, estadísticas y análisis de tendencias
                      de mercado. En cualquier caso, si el usuario no desea
                      seguir recibiendo tales comunicaciones, podrá revocar la
                      autorización de recepción otorgada mediante la presente,
                      para lo cual deberá comunicarse con Excellinked, a fin de
                      ser eliminado de su base de datos.
                    </p>
                    <p>
                      De acuerdo a la Ley PdP, el usuario podrá, en cualquier
                      momento y a través de los canales dispuestos por
                      Excellinked para dicho fin, tener acceso y derecho a
                      rectificación, oposición y cancelación de sus datos
                      personales en nuestro fichero así como a la eliminación de
                      los mismos.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Declaración de confidencialidad</b>
                    </p>
                    <p>
                      Excellinked se compromete a proteger la privacidad de los
                      usuarios en su página web www.excellinked.com. Esta
                      política es aplicable para todas las páginas web alojadas
                      en www.excellinked.com, pero no aplica a las páginas de
                      otras organizaciones que pueden tener link con nuestros
                      sitios web.
                    </p>
                  </div>
                </div>
              )}
              {this.state.labelShow === 2 && (
                <div className="col-md-10 col-offset-md-1">
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>
                        Carácter gratuito del acceso y utilización del sitio web
                      </b>
                    </p>
                    <p>
                      El acceso al contenido y/o la utilización del sitio web
                      es, en principio, gratuito para todos los usuarios. No
                      obstante lo anterior, los servicios suministrados por
                      Excellinked, están sujetos al pago de un precio en la
                      forma que se determine en las correspondientes condiciones
                      particulares de cada servicio.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Registro de usuario</b>
                    </p>
                    <p>
                      Con carácter general, el acceso a los contenidos o uso de
                      los servicios no exige la previa suscripción o registro de
                      los usuarios. No obstante, en cualquier momento
                      Excellinked puede condicionar el acceso a determinados
                      contenidos o la utilización de determinados servicios al
                      previo llenado y envío del correspondiente Formulario de
                      Registro, el cual se encuentra disponible para aquellos
                      usuarios que deseen registrarse. Asimismo, Excellinked
                      puede poner a disposición de los usuarios algunos
                      servicios cuya utilización también requieran el previo
                      llenado y envío del correspondiente Formulario de
                      Registro. Cada registro se efectuará en la forma indicada
                      a tal fin.
                    </p>
                    <p>
                      A fin de que un usuario se registre, éste deberá completar
                      todos los datos requeridos en el Formulario de Registro
                      correspondiente y, de corresponder, también los datos de
                      inicio de sesión (“Datos de Inicio de Sesión”), los mismos
                      que están compuestos de un único nombre de inicio de
                      sesión (“Nombre de Usuario”), y una contraseña de
                      seguridad (“Contraseña”). Enviado el Formulario de
                      Registro, Excellinked enviará un mensaje automático al
                      usuario a fin de verificar la cuenta de correo electrónico
                      proporcionada. Una vez verificada la cuenta de correo
                      electrónico proporcionada con la confirmación del registro
                      por el propio usuario, éste será registrado.
                    </p>
                    <p>
                      De acuerdo a lo establecido en la Ley N° 29733 - Ley de
                      Protección de Datos Personal (en adelante, “PdP”), se
                      informa que los datos personales que se obtengan como
                      consecuencia del registro de un determinado usuario, serán
                      incorporados a un fichero titularidad de Excellinked.
                    </p>
                    <p>
                      El usuario es el responsable por completo y en última
                      instancia de mantener la confidencialidad de sus datos de
                      Inicio de Sesión y, en especial de su Contraseña. En tal
                      sentido, el usuario no deberá, directa o indirectamente,
                      revelar su Contraseña a terceros por ninguna razón o bajo
                      ninguna circunstancia; ni permitir que terceros accedan a
                      su cuenta. Se prohíbe compartir, comercializar,
                      intercambiar, vender, comprar o distribuir datos de Inicio
                      de Sesión. De verificarse tal situación, los usuarios
                      responsables podrán ser suspendidos y/o dados de baja del
                      portal, a exclusivo criterio de Excellinked, sin que a tal
                      efecto sea necesario emitir advertencia o notificación
                      previa.
                    </p>
                    <p>
                      Excellinked no responde de la veracidad, exactitud,
                      pertinencia o fiabilidad de la información proporcionada
                      por el usuario, en cualquier momento y por cualquier
                      medio, en especial por la información proporcionada vía el
                      Formulario de Registro; por lo que no asume, ahora o en el
                      futuro, ninguna clase de responsabilidad por la
                      información que el usuario haya proporcionado o dejado de
                      proporcionar en su proceso de registro. Igualmente
                      Excellinked se exonera de cualquier responsabilidad ante
                      cualquier daño o perjuicio que pudiera sufrir el usuario o
                      terceros como consecuencia de errores, defectos u
                      omisiones en la información facilitada en el proceso de
                      registro.
                    </p>
                    <p>
                      Excellinked no es responsable por el uso inapropiado de
                      los datos de Inicio de Sesión del usuario, ya sea por
                      parte del propio usuario o terceros. Si el usuario
                      descubre que la confidencialidad de sus datos de Inicio de
                      Sesión está en riesgo, deberá cambiar inmediatamente su
                      Contraseña vía los medios dispuestos a tal fin tan pronto
                      como sea posible. De corresponder, Excellinked se reserva
                      el derecho de realizar las denuncias ante las autoridades
                      correspondientes a fin de encontrar a los responsables de
                      la violación de los datos de Inicio de Sesión de un
                      usuario o grupo de usuarios en particular.
                    </p>
                    <p>
                      Excellinked se reserva el derecho de eliminar cualquier
                      cuenta de registro o “Nombre de Usuario” ofensivo,
                      obsceno, inapropiado, ilegal o que sea contrario a la
                      moral y las buenas costumbres. Si algún “Nombre de
                      Usuario” infringe el presente Aviso Legal, Excellinked
                      podrá suspender o dar de baja al usuario en cuestión,
                      según su exclusiva decisión, con o sin notificación o
                      previa advertencia.
                    </p>
                    <p>
                      El usuario acepta, por el hecho de enviar el Formulario de
                      Registro y/o proporcionar cualquier dato de contacto en el
                      portal, el derecho de Excellinked de comunicarse con él
                      por todas las vías y medios posibles, incluidos los
                      electrónicos, hasta cuando soliciten la cancelación de su
                      registro, o hasta donde la legislación aplicable sobre la
                      materia lo permita.
                    </p>
                    <p>
                      Excellinked no controla, monitorea, filtra o verifica la
                      información suministrada por el usuario en el Formulario
                      de Registro, por lo que presume de buena fe que toda la
                      información proporcionada es de titularidad del usuario en
                      cuestión y que la misma es correcta, real, pertinente,
                      lícita y adecuada. Excellinked se reserva el derecho, a su
                      exclusivo criterio, dar de baja aquella información que no
                      cumplan con los parámetros establecidos en el presente
                      Aviso Legal, resulte información presuntamente falsa,
                      ilegal o inexacta, o no reflejen el verdadero y exacto
                      estado del usuario en cuestión; pudiendo incluso hacerlo
                      sin previo aviso o notificación de tal hecho al usuario.
                    </p>
                    <p>
                      El usuario garantiza y responde, en última instancia y
                      frente a cualquiera, por la veracidad, exactitud,
                      vigencia, legalidad y autenticidad de todos los datos e
                      información personal proporcionada a Excellinked en
                      cualquier momento y por cualquier medio.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Protección al menor</b>
                    </p>
                    <p>
                      Para registrarse, acceder a determinadas secciones del
                      sitio web y/o servicios, los menores de edad deben obtener
                      previamente permiso de sus padres, tutores o
                      representantes legales, quienes serán considerados
                      responsables de todos los actos realizados por los menores
                      a su cargo.
                    </p>
                    <p>
                      La plena responsabilidad en la pertinencia de los
                      contenidos y/o servicios a los que acceden los menores de
                      edad, corresponde a los mayores a cuyo cargo se
                      encuentran. El portal web puede presentar cierto contenido
                      que puede no ser apropiados para menores de edad; por lo
                      que se informa a los padres, tutores o representantes
                      legales que existen mecanismos, en particular programas
                      informáticos de filtro y bloqueo, que permiten limitar los
                      determinados tipos de contenidos disponibles y, aunque no
                      resultan infalibles, son de especial utilidad para
                      controlar y restringir los materiales a los que pueden
                      acceder los menores de edad.
                    </p>
                  </div>
                </div>
              )}
              {this.state.labelShow === 3 && (
                <div className="col-md-10 col-offset-md-1">
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Sobre los costos</b>
                    </p>
                    <p>
                      Toda la información referente a los precios de compra de
                      los servicios de Excellinked se encuentra detallada en el
                      mismo portal web o bajo un régimen de solicitud de
                      cotización.
                    </p>
                    <p>
                      En caso de existir algún costo adicional para el usuario,
                      como pueda ser un gasto de envío, impuesto o de gestión
                      general, será comunicado pertinentemente al usuario bajo
                      canales y medios claros y comprensibles.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Proceso de compra</b>
                    </p>
                    <p>
                      En el momento en que un usuario adquiere cualquier
                      producto o servicio por parte de Excellinked, el acuerdo
                      de los precios de compra y gastos añadidos se hará constar
                      a través de una cotización previa, la cual tendrá un plazo
                      de vigencia y unas características propias de la oferta a
                      la cual está relacionada.
                    </p>
                    <p />
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>Soluciones Empresariales y Proyectos Web en general</b>
                    </p>
                    <p>
                      <span>Conformidades Generales</span>
                    </p>
                    <p>
                      Toda comunicación se realizara con una única persona, ya
                      sea EL CLIENTE o alguna otra que éste designe para tal
                      fin.
                    </p>
                    <p>
                      Todas las muestras del progreso del trabajo podrán ser
                      vistas en una url que la empresa proporcionará al cliente,
                      cada vez que se publiquen avances estos serán notificados
                      vía e-mail al cliente, para esto EL CLIENTE nos
                      proporcionará una cuenta de E-mail; una vez notificado el
                      cambio se darán 3 días hábiles de plazo contados desde la
                      fecha de envió para responder su conformidad, si después
                      del plazo establecido no hay ninguna respuesta por parte
                      de EL CLIENTE, se darán por aprobados los cambios.
                    </p>
                    <p>
                      <span>Conformidades Específicas</span>
                    </p>
                    <p>
                      Se presentará un bosquejo del Site al cliente ya con su
                      logotipo e ítems de menú; EL CLIENTE podrá hacer algunos
                      CAMBIOS al diseño presentado. Luego de realizar los
                      CAMBIOS, se presentará nuevamente el bosquejo al cliente
                      para su aprobación, en este punto ya no se aceptarán
                      CAMBIOS, solo se aceptarán MEJORAS.
                    </p>
                    <p>
                      Se sugerirá el uso de las fuentes (tipografías) a usarse
                      en la página, de acuerdo a legibilidad y estilo del site,
                      si EL CLIENTE desea podrá hacernos saber su preferencia
                      por alguna fuente en especial.
                    </p>
                    <p>
                      <span>Criterios de aceptación de los servicios:</span>
                    </p>
                    <p>
                      Una vez presentado el requerimiento por parte de EL
                      CLIENTE, EXCELLINKED habrá finalizado sus obligaciones
                      explicitas de dicho requerimiento cuando cualquiera de los
                      siguientes eventos ocurra primero:
                    </p>
                    <ul data-aos="fade-up" className="content-list">
                      <li>
                        EXCELLINKED complete las tareas bajo su responsabilidad,
                        de lo cual EXCELLINKED informará por escrito al cliente,
                        quien podrá presentar sus eventuales observaciones
                        dentro de un plazo de 5 (cinco) días, transcurrido el
                        cual se considere cumplido.
                      </li>
                      <li>
                        EXCELLINKED complete su responsabilidad y se dé la
                        aceptación tácita descrita posteriormente.
                      </li>
                      <li>
                        EXCELLINKED haya proporcionado el tiempo de servicios,
                        establecidos en esta propuesta de servicios, y no exista
                        ningún cambio a través del procedimiento de control de
                        cambios.
                      </li>
                      <li>
                        Documentos de aceptación coordinados y definidos entre
                        EL CLIENTE y EXCELLINKED, serán emitidos por el líder de
                        proyecto de EL CLIENTE, se considera aceptada una fase,
                        módulo o actividad del proyecto en el momento en que se
                        emita un documento de aceptación correspondiente.
                      </li>
                    </ul>
                    <p>
                      <span>Políticas de control de Cambios</span>
                    </p>
                    <p>
                      El objetivo del control de cambios es otorgar a las partes
                      (EL CLIENTE, EXCELLINKED) un mecanismo controlado y
                      ordenado para solicitar cambios a los requerimientos
                      definidos originalmente en el alcance del proyecto
                      estipulado en los contratos, así como cualquier otro tipo
                      de cambios que afecte al costo, al cronograma o al
                      contenido del proyecto.
                    </p>
                    <p>
                      <span>Solicitud de Cambios</span>
                    </p>
                    <p>
                      Un cambio podrá ser originado por iniciativa de cualquiera
                      de las partes. Para asegurar un tratamiento uniforme,
                      todas las solicitudes serán procesadas únicamente tras la
                      recepción del pedido por comunicación formal (vía email).
                    </p>
                    <p>
                      <span>Calificación del Cambio</span>
                    </p>
                    <p>
                      Las solicitudes de cambio serán canalizadas al coordinador
                      de proyecto de EXCELLINKED quien efectuará un análisis
                      preliminar para calificar el cambio. La calificación se
                      categorizará según tiempo / esfuerzo que implique la
                      solicitud.
                    </p>
                    <p>
                      <span>Calificaciones posibles:</span>
                    </p>
                    <ul data-aos="fade-up" className="content-list">
                      <li>Cambio menor, en adelante “MEJORA”.</li>
                      <li>Cambio mayor, en adelante “CAMBIO”.</li>
                      <li>Cambio sustancial, en adelante “MODIFICACIÓN”.</li>
                      <li>
                        Cambio no enmarcado directamente en el contexto de la
                        cotización o acuerdo inicial. En este caso EXCELLINKED
                        deberá cotizar los servicios adicionales y
                        presentárselos a EL CLIENTE para su aprobación.
                      </li>
                    </ul>
                    <p>
                      <span>Procedimiento para cada Caso</span>
                    </p>
                    <p>
                      <span>
                        Para las MEJORAS se aplicará el siguiente procedimiento:
                      </span>
                    </p>
                    <p>
                      Cualquiera de las partes podrá hacer observaciones a la
                      calificación dentro de los 3 (tres) días hábiles
                      siguientes a la comunicación formal (vía email). De no
                      mediar respuesta en el plazo indicado, esta calificación
                      se dará por aprobada.
                    </p>
                    <p>
                      Aprobada la calificación como MEJORA, esta pasará a formar
                      parte del plan de trabajo y los coordinadores de proyecto
                      se asegurarán de cumplir con las responsabilidades que
                      ésta genere.
                    </p>
                    <p>
                      <span>
                        Para los CAMBIOS se aplicará el siguiente procedimiento:
                      </span>
                    </p>
                    <p>
                      Análisis detallado del CAMBIO, a cargo del coordinador de
                      Proyecto de EXCELLINKED para evaluar su impacto en los
                      cargos y/o en los términos y condiciones de los servicios.
                    </p>
                    <p>
                      <span>Decisión:</span>
                    </p>
                    <ul data-aos="fade-up" className="content-list">
                      <li>
                        Aceptado, en cuyo caso será incorporado a los servicios
                        y cada parte asumirá las nuevas responsabilidades que
                        ésta genere, así como los nuevos costos que el cambio
                        genere.
                      </li>
                      <li>
                        Rechazado, en cuyo caso será archivado junto con la
                        información pertinente a los servicios.
                      </li>
                    </ul>
                    <p>
                      <span>
                        Para las MODIFICACIONES se aplicarán las siguientes
                        estipulaciones:
                      </span>
                    </p>
                    <p>
                      En cualquier momento de la vigencia de este contrato,
                      cualquiera de las partes podrá solicitar MODIFICACIONES al
                      contrato, solicitando tales MODIFICACIONES por escrito a
                      la otra parte.
                    </p>
                    <p>
                      Si la solicitud de MODIFICACIÓN fue originada por EL
                      CLIENTE, EXCELLINKED comunicará al CLIENTE si la
                      MODIFICACIÓN puede ser hecha y su defecto en los
                      Apéndices, cargos y otros términos y condiciones de este
                      contrato.
                    </p>
                    <p>
                      Si la solicitud de MODIFICACIÓN fue originada por EL
                      CLIENTE, EXCELLINKED comunicará al CLIENTE si la
                      MODIFICACIÓN puede ser hecha y su defecto en los
                      Apéndices, cargos y otros términos y condiciones de este
                      contrato.
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>De entrega de información y los plazos</b>
                    </p>
                    <p>
                      Para el desarrollo del trabajo EL CLIENTE deberá
                      proporcionar los materiales y/o información pertinente y
                      consignada para la elaboración de su proyecto.
                    </p>
                    <p>
                      El 50% del saldo restante se cancelarán a la entrega y
                      publicación del trabajo.
                    </p>
                    <p>
                      Toda demora por cuestiones de demora en entrega de
                      información y/o aprobaciones pendientes por parte de EL
                      CLIENTE que alargue los tiempos más allá del vencimiento
                      del cronograma de trabajo establecido, EXCELLINKED podrá
                      exigir la cancelación del saldo pendiente en los
                      siguientes escenarios:
                    </p>
                    <ul data-aos="fade-up" className="content-list">
                      <li>
                        Por retrasos a causa de motivos ajenos a los objetivos
                        iniciales de lo cotizado, incluyendo modificaciones y
                        re-cambios solicitados ya aprobados por EL CLIENTE, el
                        plazo será de 07 días calendario. EXCELLINKED notificará
                        al cliente con una antelación de 2 días calendario antes
                        de dar por finalizado el proyecto, pudiendo ser ampliado
                        previa evaluación.
                      </li>
                      <li>
                        Por retrasos originados por información insuficiente
                        comprometida por parte de EL CLIENTE el plazo será de 7
                        días calendario a la fecha de término pactada, donde
                        EXCELLINKED notificará a EL CLIENTE con una antelación
                        de 5 días calendario pudiendo ser ampliado previa
                        evaluación.
                      </li>
                    </ul>
                    <p>
                      <span>De la vigencia de la oferta</span>
                    </p>
                    <p>
                      El plazo de vigencia de la oferta a contratar es por el
                      tiempo estipulado inicialmente en la cotización
                      presentada. Sin embargo, las condiciones del servicio de
                      Desarrollo Web/Software prevalecerán hasta que se dé el
                      proyecto como terminado. EXCELLINKED iniciará la ejecución
                      del proyecto tres días útiles después de la aprobación y
                      se regirá según el cronograma de tiempo establecido y
                      aprobado entre EXCELLINKED y EL CLIENTE.
                    </p>
                    <p>
                      De los horarios de atención, según los canales de
                      comunicación:
                    </p>
                    <ul data-aos="fade-up" className="content-list">
                      <li>
                        Sistema de Tickets en línea veinticuatro horas por siete
                        días a la semana.
                      </li>
                      <li>
                        Solicitud por e-mail: de lunes a viernes de 8am a 5pm.
                      </li>
                      <li>
                        Acceso telefónico: de lunes a viernes de 8am a 5pm.
                      </li>
                    </ul>
                    <p>
                      <span>Pago por los servicios</span>
                    </p>
                    <p>
                      EL CLIENTE pagará como retribución a EXCELLINKED por el
                      servicio detallado lo expuesto y en las fechas indicadas
                      en la propuesta económica.
                    </p>
                    <p>
                      EXCELLINKED presentará su factura a EL CLIENTE. En caso
                      que las facturas presentadas no coincidieran con el
                      servicio prestado de acuerdo al presente contrato, EL
                      CLIENTE podrá observarla dentro de los tres (03) días
                      hábiles siguientes a recibidas las mismas.
                    </p>
                    <p>
                      <span>Obligaciones del comprador</span>
                    </p>
                    <p>
                      Cualquier incumplimiento de parte de EL CLIENTE, en cuanto
                      al pago de la retribución a favor de EXCELLINKED, o de las
                      cuotas estipuladas, tendrá una penalidad del 5% de la
                      cuota por día de retraso.
                    </p>
                    <p>
                      <span>
                        Obligación de hacer un uso correcto del portal web y/o
                        servicios
                      </span>
                    </p>
                    <p>
                      El usuario se compromete a acceder al portal web y/o
                      utilizar los servicios de conformidad con la ley, el
                      presente Aviso Legal, las condiciones particulares de
                      ciertos Servicios y demás avisos, reglamentos de uso e
                      instrucciones puestos en su conocimiento, así como de
                      acuerdo con el orden público, la moral y las buenas
                      costumbres.
                    </p>
                    <p>
                      A tal efecto, el usuario se abstendrá de utilizar
                      cualquiera contenido publicado en el portal web, y/o los
                      servicios, así como utilizar éstos mismos con fines o
                      efectos no permitidos en el presente Aviso Legal, o que
                      sean ilícitos, prohibidos por la Ley, lesivos de los
                      derechos e intereses de terceros, dañen el buen nombre,
                      honor, reputación e intimidad de terceros, incluidos el
                      propio de Excellinked o de cualquier otra forma que sea
                      contrario a la moral o las buenas costumbres.
                    </p>
                    <p>
                      De igual manera el usuario se abstendrá de cualquier acto
                      que, en cualquier forma, pueda alterar, dañar, inutilizar,
                      sobrecargar, deteriorar o impedir la normal utilización
                      del portal web y sus, servicios, o los equipos
                      informáticos, documentos, archivos, obras y toda clase de
                      contenidos almacenados en cualquier equipo informático de
                      Excellinked , de otros usuarios o de cualquier usuario de
                      Internet.
                    </p>
                    <p>
                      <span>Confidencialidad</span>
                    </p>
                    <p>
                      EXCELLINKED se compromete expresamente a no divulgar,
                      comentar o transmitir ningún tipo de información referida
                      a las bases de datos a que tenga acceso, de propiedad de
                      EL CLIENTE, en razón del cumplimiento de sus obligaciones
                      pactadas en el presente documento.
                    </p>
                    <p>
                      <span>Limitación de responsabilidad</span>
                    </p>
                    <p>
                      EXCELLINKED no se responsabiliza por cualquier pérdida de
                      ingresos, beneficios o datos, o por daños especiales,
                      indirectos, lucro cesante o daños punitivos, como quiera
                      que fueran causados relacionados con el uso o la
                      incapacidad de uso del software desarrollado por
                      EXCELLINKED. La responsabilidad del EXCELLINKED frente a
                      EL CLIENTE, ya sea contractual o extracontractual
                      (incluida la negligencia), responderá siempre
                      proporcionalmente al daño causado.
                    </p>
                    <p>
                      <span>Prevalencia del contrato</span>
                    </p>
                    <p>
                      Ambas partes acuerdan respetar el objeto principal del
                      contrato, contenido en la cláusula primera quedando
                      entendido que regirá desde ese momento, dejando sin efecto
                      a todos y cada una de las propuestas, manifestaciones y
                      garantías, previas acuerdos orales o escritos, prevalecerá
                      por encima de cualesquiera condición complementaria de
                      cotización, orden o reconocimiento.
                    </p>
                    <p>
                      <span>Resolución del contrato</span>
                    </p>
                    <p>
                      EL CLIENTE tendrá el derecho de resolver el contrato antes
                      del vencimiento del plazo señalado en la cláusula octava
                      que antecede, para tal efecto, bastará que se curse una
                      carta notarial a EXCELLINKED, dando aviso de la resolución
                      del contrato con 30 días de anticipación, a la dirección
                      señalada en la introducción del presente instrumento.
                    </p>
                    <p>
                      <span>Jurisdicción</span>
                    </p>
                    <p>
                      Para efectos de cualquier controversia que se genere con
                      motivo de la celebración y ejecución de este contrato, las
                      partes se someten a la competencia territorial de los
                      jueces y tribunales de Lima.
                    </p>
                    <p>
                      <span>Domicilio</span>
                    </p>
                    <p>
                      Para la validez de todas las comunicaciones y
                      notificaciones a las partes, con motivo de la ejecución de
                      este contrato, ambas señalan como sus respectivos
                      domicilios los indicados en la introducción de este
                      documento. El cambio de domicilio de cualquiera de las
                      partes surtirá efecto desde la fecha de comunicación de
                      dicho cambio a la otra parte, por cualquier medio escrito.
                    </p>
                    <p>
                      <span>Supletoriedad</span>
                    </p>
                    <p>
                      En lo no previsto por las partes en el presente contrato,
                      ambas se someten a lo establecido por las normas del
                      Código Civil y demás del sistema jurídico que resulten
                      aplicables. Para cualquier cuestión litigiosa derivada de
                      la existencia o contenido de estas condiciones generales o
                      de las relaciones entre el usuario y EXCELLINKED, ambas
                      partes, con renuncia expresa a cualquier otro fuero que
                      pudiera corresponderles, se someten a la jurisdicción y
                      competencia exclusiva de los Juzgados y Tribunales de Lima
                      (Perú).
                    </p>
                  </div>
                  <div data-aos="fade-up" className="content">
                    <p>
                      <b>
                        Servicios de servidores Cloud, Hosting, dominio y afines
                      </b>
                    </p>
                    <p>
                      <span>Vigencia</span>
                    </p>
                    <p>
                      El servicio inicia desde la publicación de la página Web
                      formalmente aprobada. EL CLIENTE puede solicitar la
                      configuración de sus cuentas de correo si lo desea antes
                      de publicada su página, empezando su servicio de
                      Alojamiento Web desde ese momento.
                    </p>
                    <p>
                      Queda acordado entre EXCELLINKED y EL CLIENTE, el inicio
                      del servicio.
                    </p>
                    <p>
                      Si el inicio del servicio no se activara por EL CLIENTE a
                      los 15 días calendario siguiente a la contratación y si no
                      existe ningún acuerdo previo entre ambas partes,
                      EXCELLINKED contemplará al servicio como iniciado.
                    </p>
                    <p>
                      De los horarios de soporte, según los canales de
                      comunicación:
                    </p>
                    <ul data-aos="fade-up" className="content-list">
                      <li>
                        Sistema de Tickets en línea veinticuatro horas por siete
                        días a la semana.
                      </li>
                      <li>
                        Solicitud por e-mail: de lunes a viernes de 8am a 5pm.
                      </li>
                      <li>
                        Acceso telefónico: de lunes a viernes de 8am a 5pm.
                      </li>
                    </ul>
                    <p>
                      <span>Pago por los servicios</span>
                    </p>
                    <p>
                      EL CLIENTE pagará como retribución a EXCELLINKED por el
                      servicio detallado en el presente contrato lo detallado en
                      la propuesta económica de la cotización presentada.
                    </p>
                    <p>
                      El pago de todos los servicios se realiza por adelantado y
                      en carácter trimestral (3 meses), semestral (6 meses),
                      anual (12 meses) o bi-anual (24 meses). EXCELLINKED Acepta
                      únicamente los pagos mediante transferencia, depósito
                      bancario y/o tarjeta de crédito. El servicio no se activa
                      hasta la recepción del pago. El pago mediante tarjeta de
                      crédito se autoriza y controla en tiempo real. Una vez
                      verificada la solicitud de forma satisfactoria, EL CLIENTE
                      queda sujeto a las condiciones de reembolso de EXCELLINKED
                      Todas las transacciones monetarias se realizan en Dólares
                      Americanos o Nuevos Soles. El pago se realizará por
                      adelantado cada trimestre (3 meses), semestre (6 meses),
                      cada año (12 meses) o bi-anual (24 meses) a partir de la
                      fecha en que se formalice el contrato.
                    </p>
                    <p>
                      <span>Reactivación de cuenta</span>
                    </p>
                    <p>
                      Si se ha cancelado una cuenta a solicitud de EL CLIENTE o
                      por un problema de facturación y EL CLIENTE desea
                      reactivarla, éste deberá abonar previamente un importe no
                      reembolsable de 20.00 Dólares Americanos en concepto de
                      gastos de reactivación. La cuenta quedará reactivada una
                      vez se hayan abonado todos los cargos pendientes,
                      incluidos los de hospedaje y los gastos por cheques sin
                      fondos (si procede). Si la cuenta se ha anulado por
                      infracción de las normas, EXCELLINKED no reactivará la
                      cuenta.
                    </p>
                    <p>
                      <span>Normas de reembolso</span>
                    </p>
                    <p>
                      Para las cuentas nuevas la cuota de alta no es
                      reembolsable. Cualquier reclamación de dinero debe hacerse
                      los 30 primeros días después del pago por los servicios.
                      El pago por adelantado trimestral (3 meses), semestral (6
                      meses) y el pago anual (12 meses) no es reembolsable. Si
                      EXCELLINKED anula una cuenta por infracción de las normas
                      no reembolsará ningún importe. Las tarifas por
                      prestaciones suplementarias no son reembolsables.
                    </p>
                    <p>
                      <span>Uso inapropiado del servicio</span>
                    </p>
                    <p>
                      EXCELLINKED prohíbe sitios Web en los que se realicen:
                      Actividades ilegales incluyendo, pero sin limitarse a
                      ello, el almacenamiento o distribución ilegal de copias de
                      software protegido por Copyright, sitios en los que se
                      distribuyan herramientas para la manipulación ilegal de
                      software, violaciones de Copyright y marcas comerciales,
                      infracciones de la legislación vigente, venta o
                      distribución de contrabando ilegal.
                    </p>
                    <p>
                      El abuso de Internet incluyendo, pero sin limitarse a
                      ello, el uso abusivo del correo electrónico - envío masivo
                      de mensajes publicitarios no solicitados, distribución de
                      programas de envío masivo de cualquier tipo y forma
                      (SPAM), envío cruzado de mensajes a grandes cantidades de
                      grupos, envío de mensajes obscenos u ofensivos, amenazas a
                      otros usuarios de Internet, envío masivo de mensajes a
                      usuarios de Internet, utilización de rastreadores de
                      paquetes de comunicaciones o escáneres de puertos de
                      comunicación y envío masivo de mensajes a nuestro personal
                      de asistencia. A los infractores se les aplicará un cargo
                      de 20.00 Dólares Americanos en concepto de gastos de
                      depuración.
                    </p>
                    <p>
                      Uso abusivo de los sistemas incluyendo, pero sin limitarse
                      a ello, el uso de recursos excesivos de la CPU, el uso de
                      espacio de disco excesivo, el uso de espacio de
                      almacenamiento de correo electrónico excesivo, la
                      intromisión en nuestros sistemas, la instalación de
                      programas de ejecución continua, y la reventa de
                      redactados CGI.
                    </p>
                    <p>
                      <span>Contenido para adultos</span>
                    </p>
                    <p>
                      La determinación de lo que constituye "contenido para
                      adultos" y su prohibición corresponden exclusivamente a
                      EXCELLINKED En caso de infracción, EXCELLINKED se reserva
                      el derecho de anular la cuenta sin previo aviso y sin
                      reembolso alguno. EL CLIENTE infractor será responsable de
                      los daños provocados a los sistemas, servidores,
                      conectividad, reputación, negocio, servicio, red,
                      operaciones o equipos de EXCELLINKED Al infractor se le
                      aplicará un cargo de 20.00 Dólares Americanos en concepto
                      de gastos de depuración.
                    </p>
                    <p>
                      <span>Suspensión del servicio</span>
                    </p>
                    <p>
                      EXCELLINKED se reserva el derecho de suspender o anular el
                      acceso de cualquier cliente a cualquier parte o a la
                      totalidad de sus servicios cuando considere que EL CLIENTE
                      ha utilizado la cuenta de forma inapropiada. EXCELLINKED
                      se reserva el derecho de denegar a cualquier persona el
                      servicio o el acceso a sus servidores.
                    </p>
                    <p>
                      <span>Contenido del sitio web</span>
                    </p>
                    <p>
                      EL CLIENTE es responsable de mantener en un sistema aparte
                      (no en los servidores de EXCELLINKED) una copia de
                      seguridad de los archivos más actuales de su sitio Web.
                      EXCELLINKED no se hace responsable de las pérdidas de
                      archivos, información o datos. En caso de que se deban
                      recuperar datos de las cintas de seguridad de EXCELLINKED,
                      se aplicará un cargo de 20.00 Dólares Americanos.
                      EXCELLINKED no garantiza estar en posesión de la copia más
                      actual del sitio Web de los clientes. EXCELLINKED no será
                      responsable por cualquier tipo de problemas de seguridad
                      que puedan ocurrir por parte de EL CLIENTE.
                    </p>
                    <p>
                      EL CLIENTE accede a indemnizar por completo y a no
                      lastimar de ninguna manera legal o financiera a
                      EXCELLINKED, como resultado de una infiltración en la
                      seguridad de los servicios de EL CLIENTE.
                    </p>
                    <p>
                      <span>Actualizaciones del sistema</span>
                    </p>
                    <p>
                      EL CLIENTE entiende que las actualizaciones del sistema se
                      producen en tiempo real y en procesos por lotes en colas.
                      Por ejemplo, las contraseñas de las cuentas se actualizan
                      en procesos por lotes en colas, por lo que dicha
                      actualización no tiene un efecto instantáneo en el
                      sistema. EXCELLINKED se reserva el derecho de modificar
                      los tiempos de proceso en cualquier momento y sin previo
                      aviso. Si un cliente o un miembro del personal de
                      asistencia EXCELLINKED (autorizado por EL CLIENTE) realiza
                      cualquier modificación en una cuenta de hospedaje, queda
                      bajo la responsabilidad de EL CLIENTE el verificar que la
                      actualización haya tenido efecto en nuestro sistema.
                    </p>
                    <p>
                      <span>Confidencialidad</span>
                    </p>
                    <p>
                      EXCELLINKED se compromete a no divulgar ni prestar
                      información por ningún medio (óptico, magnético, papel,
                      red de computadores o cualquier otro) a terceros que no
                      hayan sido autorizados por escrito por parte de EL
                      CLIENTE. Por lo expuesto, EL CLIENTE al recibir una copia
                      de los accesos de su servicio, entiende que son de
                      carácter confidencial y que EXCELLINKED no será
                      responsable de las acciones que terceros puedan hacer uso
                      de la información que haya sido entregada desde EL
                      CLIENTE.
                    </p>
                    <p>
                      <span>Asistencia</span>
                    </p>
                    <p>
                      Se presta un servicio gratuito de asistencia al cliente
                      por correo electrónico mediante formularios de contacto
                      del panel de control de EL CLIENTE. Los mensajes de correo
                      electrónico de asistencia técnica se responderán en el
                      plazo de 24 horas si es posible. Las consultas relativas a
                      facturación se responderán en el plazo de un día
                      laborable. EL CLIENTE se compromete a no efectuar envíos
                      masivos de mensajes al servicio de asistencia.
                    </p>
                    <p>
                      <span>Prestaciones adicionales</span>
                    </p>
                    <p>
                      Cuando se añade una prestación, se aplican los
                      correspondientes cargos al cliente por el mes y por cada
                      mes subsiguiente hasta el final de su ciclo de
                      facturación. Las tarifas de las prestaciones no se
                      prorratean. Notificación de renovación y plan de
                      facturación. La notificación de renovación se remite por
                      correo electrónico al interesado treinta (30), quince (15)
                      y siete (7) días antes del final del ciclo de facturación.
                      Los clientes de pago mensual o trimestral pueden optar por
                      el pago anual poniéndose en cola al menos 10 días antes
                      del final de su ciclo de facturación actual. Los clientes
                      de pago anual deben renovar su opción de pago anual al
                      menos 10 días antes del final de su ciclo de facturación.
                      Si EL CLIENTE no renueva su opción de pago anual la cuenta
                      pasará automáticamente a la misma modalidad de pago del
                      período anterior. Las tarjetas de crédito se cargan 10
                      días antes del final de ciclo de facturación. Una vez
                      verificado el pago, se remitirá al interesado que lo
                      solicite una notificación por correo electrónico.
                    </p>
                    <p>
                      <span>Suspensión del servicio en caso de emergencia</span>
                    </p>
                    <p>
                      EXCELLINKED suspenderá el servicio Web en el caso de algún
                      problema técnico o de seguridad, Esta suspensión será en
                      coordinación con EL CLIENTE con un tiempo definido que
                      permita encontrar la solución, después de la cual, el
                      servicio podrá ser establecido.
                    </p>
                    <p>
                      <span>Indemnidad</span>
                    </p>
                    <p>
                      EL CLIENTE acuerda defender, mantener indemne y eximir a
                      EXCELLINKED de cualquier responsabilidad derivada de
                      productos defectuosos vendidos a los clientes a través de
                      servidores de EXCELLINKED , daños personales o materiales
                      ocasionados por productos o servicios vendidos o
                      distribuidos a través de servidores EXCELLINKED , todo
                      material que infrinja o presuntamente infrinja los
                      derechos de terceros a través de servidores EXCELLINKED y
                      todo material que suponga libelo o presunto libelo contra
                      terceros a través de servidores EXCELLINKED.
                    </p>
                    <p>
                      <span>Limitación de responsabilidad</span>
                    </p>
                    <p>
                      EXCELLINKED no se hace responsable de los perjuicios que
                      pueda sufrir el negocio de cliente alguno.
                    </p>
                    <p>
                      EXCELLINKED no asume garantía alguna, explícita o
                      implícita, de los servicios que presta. El uso del
                      servicio de EXCELLINKED de hospedaje de sitios Web y
                      correo electrónico se realiza exclusivamente por cuenta y
                      riesgo de EL CLIENTE. Los servicios de EXCELLINKED se
                      prestan "tal cual, tal como están disponibles".
                      EXCELLINKED no garantiza que sus servicios no vayan a
                      sufrir interrupciones o errores.
                    </p>
                    <p>
                      EXCELLINKED hará, de buena fe, todo lo posible para que
                      sus servicios estén a disposición de tantos usuarios de
                      Internet como sea posible con un mínimo de interrupciones.
                    </p>
                    <p>
                      EXCELLINKED no será en ninguna circunstancia responsable
                      de reclamación alguna derivada de la utilización o la
                      incapacidad de utilizar sus servicios, incluyendo, pero
                      sin limitarse a ello, las interrupciones del servicio,
                      errores de EL CLIENTE, problemas de conexión con Internet,
                      comunicaciones fallidas, acceso no autorizado a los
                      servidores de EXCELLINKED , problemas con sociedades
                      registradoras de dominios, memorización de DNS, congestión
                      del ancho de banda de Internet, cortes de energía,
                      vandalismo y desastres naturales. EXCELLINKED se reserva
                      el derecho de revisar sus normas en cualquier momento.
                    </p>
                    <p>
                      <span>Información de carácter general</span>
                    </p>
                    <p>
                      Las presentes Condiciones Generales, así como cualquier
                      relación entre usted como Usuario y EXCELLINKED, se
                      regirán por la legislación Peruana. Para cualquier
                      cuestión litigiosa derivada de la existencia o contenido
                      de estas Condiciones Generales o de las relaciones entre
                      el Usuario y EXCELLINKED, ambas partes, con renuncia
                      expresa a cualquier otro fuero que pudiera
                      corresponderles, se someten a la jurisdicción y
                      competencia exclusiva de los Juzgados y Tribunales de Lima
                      (Perú).
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
