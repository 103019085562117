import React, { Component } from "react";
import { Link } from "react-router-dom";

import ContactSeccion from "./../components/contact";

export default class ServicePage extends Component {
  componentDidMount() {
    document.title = "Servicio XL+ | excellinked+";
  }

  render() {
    return (
      <div onLoad={this.props.loadPage}>
        <section className="page-int-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 col-offset-md-1">
                <h1 className="title">
                  Servicios de <span>XL+</span>
                </h1>
                <div className="text-content">
                  <p>
                    Nuestros especialistas te mantendrán involucrado e informado
                    durante todo el proceso de la implementación.
                  </p>
                  <p>
                    Cuanto mejor sea tu experiencia, mayor será nuestra
                    satisfacción.
                  </p>
                </div>
                <figure
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="img-content flex align-items-center"
                >
                  <a href="img/services.jpg" className="progressive replace">
                    <img
                      src="img/servicesblur.jpg"
                      className="preview"
                      alt="services"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="center">
              <h2 data-aos="fade-up" className="subtitle">
                Servicios+
              </h2>
            </div>
            <div className="row">
              <div className="grid grid-xs-1 col-md-10 grid-md-1 col-offset-md-1 grid-xl-2">
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Automation+</b>
                    </p>
                    <p>
                      Soluciones de automatización de procesos, consiguiendo
                      productos de gran valor como la implementación de domótica
                      en un hogar.
                    </p>
                    <p>
                      <Link to="/domotica">Ver más</Link>
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Realidad+ Mixta</b>
                    </p>
                    <p>
                      Es la evolución de la Realidad Aumentada. Creamos juegos y
                      aplicaciones añadiendo los beneficios de la Realidad
                      Virtual.
                    </p>
                    <p>
                      <Link to="/realidad-mixta">Ver más</Link>
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Web Apps+</b>
                    </p>
                    <p>
                      Nuestros trabajos podrán abarcar desde todo el diseño y el
                      desarrollo, de forma que te garantizamos el más estricto
                      cumplimiento de estándares y uso eficiente de las últimas
                      tecnologías.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Android+ & iOS+</b>
                    </p>
                    <p>
                      Analizamos tu proyecto y buscamos la tecnología que más se
                      adapte a tu requerimiento y presupuesto. Apps y juegos
                      desarrollados desde el UX hasta su publicación. También
                      brindamos varios tipos de mantenimiento y soporte de
                      acuerdo a tus necesidades.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="800"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Trabajos+ de infraestructura</b>
                    </p>
                    <p>
                      Instalamos cámaras de seguridad, datacenter y equipos de
                      telecomunicaciones. Ademas brindamos servicios de Hosting
                      y Cloud de alto performance y seguridad. Automation y
                      domótica en general.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>e-Commerce+</b>
                    </p>
                    <p>
                      Entendemos que para vender algo, necesitas lograr llegar a
                      tus clientes. Por ello, con nuestras soluciones en
                      e-Commerce, permitirás que tu producto se conozca en
                      varios canales de venta a la vez. A su vez, podrás conocer
                      mejor quien es tu consumidor, enfocando así tus recursos
                      en hacer crecer tu marca.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Hosting+ & Cloud+</b>
                    </p>
                    <p>
                      Nuestros servicios son ofrecidos con servidores de última
                      generación, administrados por el propio equipo de XL que
                      van de la mano con el mejor soporte técnico evolutivo y
                      que estará siempre atento a brindarte un soporte 24x7 de
                      mínimo riesgo.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="500"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Juegos Publicitarios+</b>
                    </p>
                    <p>
                      Transformamos tu idea creativa en una propuesta
                      interesante y real para lograr una campaña novedosa y que
                      te permita maximizar la exposición y llegada de tu marca.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="800"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Realidad+ Virtual</b>
                    </p>
                    <p>
                      Creamos experiencias interactivas a través de modelados 3D
                      y un entorno de programación, basándonos en técnicas de
                      inmersión y simulación de situaciones gráficas
                      realísticas. Disponible para Smartphones y tabletas
                      Android & iOS.
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="800"
                    className="group-card"
                  >
                    <div className="icon-card flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                      >
                        <g fill="none">
                          <g fill="#FFF">
                            <path d="M6.3 13C6.2 13 6 12.9 5.9 12.8L0.2 7.5C-0.1 7.2-0.1 6.8 0.2 6.5 0.5 6.2 0.9 6.2 1.2 6.5L6.3 11.2 15.5 0.3C15.8 0 16.2-0.1 16.5 0.2 16.8 0.4 16.9 0.9 16.6 1.2L6.9 12.7C6.8 12.9 6.6 13 6.4 13 6.4 13 6.4 13 6.3 13Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p>
                      <b>Plataforma Google Maps</b>
                    </p>
                    <p>
                      Creamos soluciones con experiencias ágiles y
                      personalizadas que permitan acercar el mundo real a tus
                      usuarios a través de mapas estáticos y dinámicos, imágenes
                      de Street View y vistas en 360º.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-offset-md-1 col-md-10">
                <hr />
                <div className="group-faq">
                  <div data-aos="fade-up" className="group-faq-title">
                    <p>
                      <b>Preguntas Frecuentes</b>
                    </p>
                    <p>Desarrollo+ Web</p>
                  </div>
                  <div className="row">
                    <div className="group-faq-content grid grid-md-1">
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>
                            ¿Por qué debo invertir en una web para mi empresa?
                          </b>
                        </p>
                        <p>
                          Porque vivimos una nueva etapa en la que existen
                          infinitas oportunidades en el mundo online. Una web de
                          tu empresa es como si tuvieras un local en cada país
                          del mundo, esto significa que multiplicas tus
                          beneficios y el reconocimiento de tu empresa con los
                          costos más bajos.
                        </p>
                      </div>
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>¿Qué beneficios le va a dar a mi negocio?</b>
                        </p>
                        <p>
                          Mayor facilidad para llegar a un target específico,
                          mayores posibilidades de realizar estudios
                          estadísticos de quién y/o dónde se venden más tus
                          productos o servicios. Posibilidad de implementar una
                          tienda online, lo cual supone hoy en día mucho más
                          comodidad para el cliente y para el empresario. Muy
                          fácil gestión. Personalización 100% y mucho más.
                        </p>
                      </div>
                      <div data-aos="fade-up" className="column">
                        <p>
                          <b>
                            Quiero una web para mi empresa, ¿qué debo hacer?
                          </b>
                        </p>
                        <p>
                          Contacta con nosotros a través de este{" "}
                          <Link to="/contacto">link</Link> solicitando el
                          servicio de Desarrollo Web y nosotros te brindaremos
                          la mejor solución para tu negocio.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSeccion />
      </div>
    );
  }
}
