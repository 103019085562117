import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import "./main.scss";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const config = {
  apiKey: "AIzaSyDNSmBYEJ_ZpzzNYDie2nRPy3JCzKBiplA",
  authDomain: "xlservidor.firebaseapp.com",
  databaseURL: "https://xlservidor.firebaseio.com",
  projectId: "xlservidor",
  storageBucket: "xlservidor.appspot.com",
  messagingSenderId: "366521830814",
};

firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
