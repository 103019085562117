import React from 'react';
import { Link } from 'react-router-dom';

const ContactSeccion = () => (
  <section className="contact-section">
    <div className="icon-contact flex align-items-center">
      <Link to="/contacto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="25"
          viewBox="0 0 22 25"
        >
          <g fill="none">
            <g fill="#FFF">
              <path d="M16.4 24.1C16.3 24.1 16.1 24 16 23.9L12.3 21.2C9.1 21.6 5.8 20.5 3.5 18.2 -0.7 14-0.7 7.3 3.5 3.2 7.6-1 14.4-1 18.5 3.2 21.1 5.8 22.2 9.5 21.3 13.1 21.3 13.1 21.3 13.2 21.3 13.2 21.2 13.7 21 14.2 20.8 14.7L19.2 18.6C19 18.9 18.6 19.1 18.3 19 17.9 18.8 17.7 18.4 17.9 18L19.5 14.1C19.7 13.7 19.8 13.3 19.9 12.8 19.9 12.8 19.9 12.8 20 12.8 20.7 9.7 19.8 6.4 17.5 4.2 13.9 0.6 8.1 0.6 4.5 4.2 0.9 7.7 0.9 13.6 4.5 17.2 6.6 19.3 9.5 20.2 12.4 19.8 12.6 19.7 12.8 19.8 12.9 19.9L16.1 22.2 16.8 20.6C17 20.2 17.4 20.1 17.7 20.2 18.1 20.4 18.3 20.8 18.1 21.1L17.1 23.6C17 23.8 16.8 24 16.6 24 16.6 24 16.5 24.1 16.4 24.1Z" />
              <path d="M15.5 8.5L7 8.5C6.7 8.5 6.4 8.2 6.4 7.9 6.4 7.6 6.7 7.4 7 7.4L15.5 7.4C15.8 7.4 16 7.6 16 7.9 16 8.2 15.8 8.5 15.5 8.5Z" />
              <path d="M15.5 11.7L7 11.7C6.7 11.7 6.4 11.5 6.4 11.2 6.4 10.8 6.7 10.6 7 10.6L15.5 10.6C15.8 10.6 16 10.8 16 11.2 16 11.5 15.8 11.7 15.5 11.7Z" />
              <path d="M11.8 15L7 15C6.7 15 6.4 14.7 6.4 14.4 6.4 14.1 6.7 13.8 7 13.8L11.8 13.8C12.1 13.8 12.4 14.1 12.4 14.4 12.4 14.7 12.1 15 11.8 15Z" />
            </g>
          </g>
        </svg>
      </Link>
    </div>
    <div data-aos="fade-up" className="container">
      <p>
        <Link to="/contacto">¡Comencemos tu proyecto!</Link>
      </p>
      <p>
        <span>¡Hagamos algo genial!</span>
      </p>
    </div>
  </section>
);

export default ContactSeccion;
