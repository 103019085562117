import React, { Component } from 'react';

export default class Player extends Component {
  constructor() {
    super();
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
  }

  playVideo(e) {
    e.target.play();
  }

  pauseVideo(e) {
    e.target.pause();
  }

  render() {
    return (
      <video
        loop
        preload="none"
        onMouseOver={this.playVideo}
        onClick={this.playVideo}
        onMouseOut={this.pauseVideo}
        poster={this.props.poster}
      >
        <source src={this.props.src} type="video/mp4" />
        Video tag not supported. Download the video{' '}
        <a href={this.props.src}>here</a>.
      </video>
    );
  }
}
